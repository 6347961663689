import {
  cancelFollowup, createApprovalInvite,
  deleteMessageRequest, fetchDriverSuggestions,
  followupNowRequest,
  postMessageRequest,
  removeInvite, saveDecisionUserApproval,
  saveDecisionUserSupport,
  scheduleNewFollowupDueDate,
  toggleDriverCommentLikeRequest,
  toggleLikeDecisionUserSupport,
  ToggleMessageLikeRequest,
  updateCollaborationContacts,
  updateDecisionCategories,
  updateDecisionContext,
  updateDecisionTags, updateDecisionUserApproval,
  updateFollowupDueDate,
  updateFollowupRequest,
  updateMessageRequest,
  updateRecordDecisionData,
  loadLegacyDecisionData,
  fetchDriverSummarize,
  vectorStoreUploadFiles, aiDecisionUploader, aiResponse
} from "../../utils/Api";
import EntryPoint from "../../EntryPoint";
import {isBlank, isPresent, uniqueBy} from "../../helpers/common";
import {
  deepEqual,
  failedResponseHandler,
  isResponseFailed,
  updateDecisionDataInStores
} from "../../helpers/store_helpers";
import {updateTreeData} from "../tree/common_actions";
import { driversToArray } from "../../helpers/drivers_helpers";
import { loadDecisionsFailure, updateDecisionData } from "./common_actions";
import {updateUserData} from "../current_user/actions";
import {updateShareData} from "../share/actions";
import {updateOrgData} from "../current_org/actions";
import {saveSideBarData} from "../sidebar/common_actions";
import { recalculateDriversCompletion } from "../decision_set/helper_actions";
import {loadWizardAiFailure, updateWizardData} from "../wizard/actions";

export function loadLegacyDecision(callback = () => {}) {
  return (dispatch, getStore) => {
    const { data } = EntryPoint.instance;
    loadLegacyDecisionData({objectSlug: data.objectSlug, controllerName: "legacy_dsp" }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

      const { data } = response;
      const { decision, current_org, current_user, share_data, default_user_avatar_url } = data;
      const currentUserData = { ...getStore().current_user, ...current_user }
      dispatch(updateDecisionData({ ...decision, default_user_avatar_url, users: uniqueBy([...decision.users, currentUserData], 'email'), loaded: true }));
      dispatch(updateUserData({ ...current_user }));
      dispatch(updateShareData({ ...share_data }));
      if (isPresent(current_org)) {
        dispatch(updateOrgData({ ...current_org }))
      }
      callback(true)
    })
  }
}

// Decision feedbacks
export const postUserMessage = (options, callback) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  postMessageRequest({objectSlug, ...options }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response;
    const { feedback } = data;
    const feedbacks = [...getState().decision.feedbacks, feedback]
    updateDecisionDataInStores(dispatch, getState, { feedbacks })
    updateDecisionCollaborators(dispatch, getState, data)
    callback(true)
  })
}
export const updateUserMessage = ({ slug, ...options }, callback) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  updateMessageRequest({objectSlug, slug, ...options}).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response;
    const { feedback } = data;
    const feedbacks = getState().decision.feedbacks.map(item => item.id === slug ? {...item, ...feedback } : item)
    updateDecisionDataInStores(dispatch, getState, { feedbacks })
    updateDecisionCollaborators(dispatch, getState, data)
    callback(true)
  })
}
const updateDecisionCollaborators = (dispatch, getState, data) => {
  const { users, invites } = data;
  if (isPresent(users)) {
    updateDecisionDataInStores(dispatch, getState, { users, invites })
  }
}
export const deleteUserMessage = (slug) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  deleteMessageRequest({objectSlug, slug}).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure });

    const feedbacks = getState().decision.feedbacks.filter(item => item.id !== slug)
    updateDecisionDataInStores(dispatch, getState, { feedbacks })
  })
}
export const toggleMessageLike = (slug, callback) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  ToggleMessageLikeRequest({objectSlug, slug}).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response;
    const { feedback } = data;
    const feedbacks = getState().decision.feedbacks.map(item => item.id === slug ? {...item, ...feedback } : item)
    updateDecisionDataInStores(dispatch, getState, { feedbacks })
    callback(true)
  })
}

// Drivers comments
const driversCommentsTOArray = (drivers) => {
  const result = []
  driversToArray(drivers).forEach(({ driver }) => {
    (driver.comments || []).forEach(comment => {
      result.push({ ...comment, type: 'Driver', object: driver })
    })
  })
  return result;
}
export const collectDecisionDriversComments = (dispatch, getState, drivers) => {
  if (isBlank(drivers)) return;

  const driver_comments = driversCommentsTOArray(drivers)
  if (!deepEqual(getState().decision.driver_comments, driver_comments)) {
    updateDecisionDataInStores(dispatch, getState, { driver_comments })
  }
}

export const toggleDriverCommentLike = (driverSlug, commentSlug, callback) => (dispatch, getState) => {
  const { objectSlug, controllerName } = EntryPoint.instance;
  toggleDriverCommentLikeRequest({ controllerName, objectSlug, driverSlug, commentSlug }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response;
    const { feedback } = data;
    const driver_comments = (getState().decision.driver_comments || []).map(item => item.id === commentSlug ? {...item, ...feedback } : item)
    updateDecisionDataInStores(dispatch, getState, { driver_comments })
    callback(true)
  })
}

// Followups data
export const createNewDueDate = (data) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  scheduleNewFollowupDueDate({ objectSlug, data }).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure });

    const { data } = response;
    const { followups } = data;
    updateDecisionDataInStores(dispatch, getState, { followups })
  })
}
export const updateDueDate = (slug, data) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  updateFollowupDueDate({ objectSlug, slug, data }).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure });

    const { data } = response;
    const { followups } = data;
    updateDecisionDataInStores(dispatch, getState, { followups })
  })
}
export const cancelFollowupDueDate = () => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  cancelFollowup(objectSlug).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, {
      ...response,
      callback: loadDecisionsFailure
    });

    const { data } = response;
    const { followups } = data;
    updateDecisionDataInStores(dispatch, getState, { followups })
  })
}

// Decision ChatGPT
export const chatGptApiRequest = (data, callback, setDriverSuggestions) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance;
  fetchDriverSuggestions(objectSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);
    const preparedSuggestions = response.data.data.suggestions

    setDriverSuggestions(suggestions => [...suggestions, ...preparedSuggestions])
    callback(true)
  })
}

export const chatGptDriverSummarizeRequest = (data, callback, setContext, decisionSlug) => (dispatch, getState) => {
  fetchDriverSummarize(decisionSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response.data;
    const { drivers, queries, decision } = data;
    dispatch(saveSideBarData({ drivers, leftError: '' }));
    dispatch(updateTreeData({ data, drivers }));
    dispatch(recalculateDriversCompletion({ drivers }));
    updateDecisionDataInStores(dispatch, getState, {...decision})

    setContext(queries)
    callback(true)
  })
}

export const aiResponseRequest = (data, callback, setContext, decisionSlug) => (dispatch, getState) => {
  aiResponse(decisionSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response.data;
    const { queries, decision, drivers } = data;
    dispatch(saveSideBarData({ drivers, leftError: '' }));
    dispatch(updateTreeData({ data, drivers }));
    updateDecisionDataInStores(dispatch, getState, {...decision})

    setContext(queries)
    callback(true)
  })
}

export const aiDecisionUploaderRequest = (callback) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  aiDecisionUploader(objectSlug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadWizardAiFailure }, callback);

    const { data } = response;
    const { wizard, decision } = data;
    dispatch(updateWizardData({ ...wizard }));
    updateDecisionDataInStores(dispatch, getState, {...decision})
    callback()
  })
}
export const vectorStoreUploadFilesRequest = (data, callback, decisionSlug, callbackFailure) => (dispatch) => {
  vectorStoreUploadFiles(decisionSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: callbackFailure }, callback);

    callback(true)
  })
}

// Decision user approvals
export const saveUserApproval = (data, callback) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  saveDecisionUserApproval(objectSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response;
    const { user_approval } = data;
    const user_approvals = [
      ...getState().decision.user_approvals.filter(s => s.user_email !== user_approval.user_email),
      user_approval
    ]
    updateDecisionDataInStores(dispatch, getState, { user_approvals })
    callback(true)
  })
}

export const updateUserApproval = (data, callback) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  updateDecisionUserApproval(objectSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response;
    const { user_approval } = data;
    const user_approvals = [
      ...getState().decision.user_approvals.filter(s => s.user_email !== user_approval.user_email),
      user_approval
    ]
    updateDecisionDataInStores(dispatch, getState, { user_approvals })
    callback(true)
  })
}

// Decision user supports
export const saveUserSupport = (data, callback) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  saveDecisionUserSupport(objectSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response;
    const { user_support } = data;
    const user_supports = [
      ...getState().decision.user_supports.filter(s => s.user_email !== user_support.user_email),
      user_support
    ]
    updateDecisionDataInStores(dispatch, getState, { user_supports })
    callback(true)
  })
}

export const toggleUserSupportCommentLike = (slug) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  toggleLikeDecisionUserSupport(objectSlug, slug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure });

    const { data } = response;
    const { user_support } = data;
    const user_supports = getState().decision.user_supports.map(item => item.id === slug ? {...item, ...user_support } : item)
    updateDecisionDataInStores(dispatch, getState, { user_supports })
  })
}

// Decision results
export function followupNow(data, callback) {
  return (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    followupNowRequest({ objectSlug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

      const { data } = response;
      const { followups, choices, follow_up_choices } = data;
      const decision = getState().decision
      updateDecisionDataInStores(dispatch, getState, {...decision, followups, choices, follow_up_choices })
      callback(true)
    })
  }
}
export function updateFollowup(data, slug, callback) {
  return (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    updateFollowupRequest({ objectSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

      const { data } = response;
      const { followups, choices, follow_up_choices } = data;
      const decision = getState().decision
      updateDecisionDataInStores(dispatch, getState, { ...decision, followups, choices, follow_up_choices})
      callback(true)
    })
  }
}

// Approval Invites
export function createApprovalInvites({ emails, contacts, comment, callback }) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    const data = { collaboration: { emails }, contacts: JSON.stringify(contacts), comment: comment, web_approval_invite: true }
    createApprovalInvite({
      controllerName, objectSlug, data
    }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

      const { data } = response;
      const { invites, users,  user_approvals } = data;
      updateDecisionDataInStores(dispatch, getState, { invites, users, user_approvals })
      callback(true)
    })
  }
}

// Invites
export function submitInvites({ emails, type, contacts, callback }) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    const data = { collaboration: { type, emails }, contacts: JSON.stringify(contacts) }
    updateCollaborationContacts({
      controllerName, objectSlug, data
    }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

      const { data } = response;
      const { invites, users } = data;
      updateDecisionDataInStores(dispatch, getState, { invites, users })
      callback(true)
    })
  }
}
export function removeDecisionInvite(data, callback) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    removeInvite({ controllerName, objectSlug, data }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

      const { data } = response;
      const { invites, users, drivers, recommendation, ...decision } = data;
      updateDecisionDataInStores(dispatch, getState, {
        ...decision, invites, users,
        recommendation: { ...getState().decision.recommendation, ...recommendation }
      })
      dispatch(updateTreeData({ drivers }));
      dispatch(recalculateDriversCompletion({ drivers }));
      callback(true)
    })
  }
}

// Context
export const updateContext = (value, callback = () => {}) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance
  updateDecisionContext({ objectSlug, value }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response;
    const { collaboration_context } = data;
    updateDecisionDataInStores(dispatch, getState, { collaboration_context })
    callback(true)
  })
}

// Decision Tags
export function addTag(value, callback) {
  return (dispatch, getState) => {
    if (isBlank(value)) return false;

    const decision_tags = [
      ...getState().decision.decision_tags.map(tag => tag.display_name),
      value
    ]
    updateDecisionTagsData(dispatch, getState, decision_tags, callback)
  }
}
export const removeTag = (id) => (dispatch, getState) => {
  const decision_tags = getState().decision.decision_tags.filter(tag => tag.id !== id).map(tag => tag.display_name)
  updateDecisionTagsData(dispatch, getState, decision_tags)
}
const updateDecisionTagsData = (dispatch, getState, decision_tags, callback = () => {}) => {
  const { objectSlug } = EntryPoint.instance
  updateDecisionTags({objectSlug, decision_tags}).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response;
    const { decision_tags } = data;
    dispatch(updateDecisionData({ decision_tags }));
    callback(true)
  })
}

// Decision categories
export const submitCategories = (data, callback) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance
  updateDecisionCategories(objectSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response;
    const { category_options, selected_categories } = data;
    updateDecisionDataInStores(dispatch, getState, { category_options, selected_categories })
    callback(true)
  })
}

export const updateRecordDecision = (data, callback = () => {}) => (dispatch, getState) => {
  const { objectSlug } = EntryPoint.instance;
  updateRecordDecisionData({ objectSlug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadDecisionsFailure }, callback);

    const { data } = response;
    const { decision } = data;
    updateDecisionDataInStores(dispatch, getState, { ...decision })
    callback(true)
  })
}

