import React from 'react';
import { isBlank, isPresent } from "../../../../helpers/common";
import { BorderedFilterStyle } from "../../../../tree_wizard/styles/choice_entry_styles";
import Select from "react-select";

const SelectForecastScenarioSourceRow = ({ selectedSource, onSelectScenario, orgForecastScenarios }) => {
  const options = orgForecastScenarios.flatMap(scenario => [
    {
      value: scenario.id,
      label: <span style={{ wordBreak: 'break-word' }}>
          {scenario.display_name}
        </span>,
      isDisabled: isPresent(scenario.default_scenario) || isPresent(scenario.deleted_at)
    },
    ...(scenario.actualized || []).map(copy => ({
      value: copy.id,
      label: <span style={{ wordBreak: 'break-word' }}>
        <i className={`fa-solid fa-lock icon fa-sm ms-2 me-1`} />
        {copy.display_name}
      </span>
    }))
  ])

  return <div className="existing-forecast-scenario-source-dropdown">
    <Select
      value={selectedSource}
      onChange={onSelectScenario}
      components={{ IndicatorSeparator: () => null }}
      styles={BorderedFilterStyle}
      placeholder="Add a forecast simulator scenario"
      options={options} />
  </div>
}


export default ({
                  orgForecastScenarios,
                  selectedSource,
                  onSelectScenario, hidden = false
                }) => {
  if (hidden || isBlank(orgForecastScenarios)) return null;

  return <div className="mt-2">
    <div className="new-source-entries">
      <SelectForecastScenarioSourceRow selectedSource={selectedSource} onSelectScenario={onSelectScenario}
                                       orgForecastScenarios={orgForecastScenarios}/>
    </div>
  </div>
}
