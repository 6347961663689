import React from 'react';
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import {
  setDecisionAiResponseSidebarOpen,
  setDriverAiResponseSidebarOpen,
  setRecommendationAiResponseSidebarOpen
} from "../../../store/sidebar/actions";
import { isBlank, } from "../../../helpers/common";
import { isDSightDecision } from "../../../helpers/home_decision_helpers";
import DataSource, { Type } from "../../../models/data_source";
import Decision from "../../../models/decision";
import {answeredChildrenDrivers, answeredChildrenRatingDrivers} from "../../../helpers/gpt_helpers";
import {MIN_DRIVERS_FOR_AI_ANALYSIS} from "../../../helpers/drivers_helpers";

const AiResponseButton = ({ sidebar, setDriverAiResponseSidebarOpen, driverData, decision,
                            type, setRecommendationAiResponseSidebarOpen, dataSources,
                            setDecisionAiResponseSidebarOpen, current_org, addClass = '', isRoot = false }) => {
  if (isBlank(driverData) || !current_org.allow_gpt) return null;
  if (isDSightDecision(decision)) return null;

  const decisionObject = new Decision(decision, sidebar.drivers);
  const isRated = !decisionObject.isRateAndCompareDriverChoicesHidden

  if (!isRated && answeredChildrenDrivers(driverData, isRoot).length < MIN_DRIVERS_FOR_AI_ANALYSIS && isBlank(dataSources)) return null;
  if (isRated && answeredChildrenRatingDrivers(driverData, isRoot).length < MIN_DRIVERS_FOR_AI_ANALYSIS && isBlank(dataSources)) return null;

  const openAiResponsePanel = () => {
    if (type === 'decision') setDecisionAiResponseSidebarOpen(true);
    if (type === 'recommendation') setRecommendationAiResponseSidebarOpen(true);
    if (type === 'driver') setDriverAiResponseSidebarOpen(true, sidebar.driverSlug);
  };

  return <div className={`${addClass}`}>
    <Button onClick={openAiResponsePanel} className="btn-secondary btn w-100 h-auto mb-2" style={{ minHeight: 48 }}>
      See CloverpopAI response
    </Button>
  </div>
};

export const filterAndSortDataSources = (allDataSources) => {
  const selectableDataSources = allDataSources.filter(source => Type.DECISION_DATA_SOURCE === source.type);
  const allowedDataSources = selectableDataSources.filter(source => new DataSource(source).isAiSupported);
  return allowedDataSources.sort((x, y) => ((x.title || x.link_url) > (y.title || y.link_url) ? 1 : -1));
}
export const getFilteredDataSources = (allowedDataSources, hideInPanel) => {
  return allowedDataSources.filter(source => !hideInPanel || !source[hideInPanel]);
};

const mapStateToProps = ({ sidebar, decision, current_org }) => ({ decision, current_org, sidebar });

export default connect(mapStateToProps, {
  setRecommendationAiResponseSidebarOpen, setDecisionAiResponseSidebarOpen, setDriverAiResponseSidebarOpen
})(AiResponseButton);
