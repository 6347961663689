import { useState, useMemo } from 'react';
import { isBlank, isPresent } from "./common";

export const filterCategories = ({ available_categories }, selected_categories) =>
  useMemo(() =>
      available_categories.filter(c => c.options.some(opt => selected_categories.some(id => id === opt.id)))
    , [selected_categories, available_categories]
  )

export const fetchRequiredCategories = ({ available_categories }, selectedCategories) =>
  available_categories.filter(ac => selectedCategories.find(({ slug }) => slug === ac.slug)?.required)

export const fetchDefaultSortedSelectedCategories = (org_categories, selected_categories) =>
  org_categories.available_categories.reduce((result, category) => {
    const hash = (selected_categories || []).find(({ slug }) => category.slug === slug)
    if (isPresent(hash)) result.push(hash)
    return result;
  }, [])

export const selectCategoryState = ({
                                      org_categories,
                                      categoriesData, setCategoriesData,
                                      selected_categories = []
                                    }) => {
  const [selectedCategories, setSelectedCategories] = useState(
    fetchDefaultSortedSelectedCategories(org_categories, selected_categories)
  )
  const [lastCategory, setLastCategory] = useState(null)

  const onSelectCategory = ({ value }) => {
    const selectedCategory = org_categories.available_categories.find(c => c.slug === value)
    setLastCategory(value)
    setCategoriesData([
      ...categoriesData,
      ...selectedCategory.options.filter(o => o.default_value).map(({ id }) => id)
    ])
    setSelectedCategories([
      ...selectedCategories,
      { slug: value, required: false }
    ])
  }

  const onChangeCategoryRequired = (slug, required) => {
    setSelectedCategories(
      selectedCategories.map(h => h.slug === slug ? {...h, required} : h)
    )
  }

  const onRemoveCategory = (slug) => {
    const removedCategory = org_categories.available_categories.find(c => c.slug === slug)
    setCategoriesData(
      categoriesData.filter(option_id => !removedCategory.options.some(o => o.id == option_id))
    )
    setSelectedCategories(selectedCategories.filter(h => h.slug !== slug))
  }

  return {
    lastCategory, setLastCategory,
    selectedCategories, setSelectedCategories, onSelectCategory,
    onChangeCategoryRequired, onRemoveCategory
  }
}
