import {isPresent} from "./common";

export const AI_ERROR_MESSAGE = 'Oops! Something happened. Please try again.';
export const REVIEWING_DRIVERS_TEXT = 'CloverpopAI is reviewing the contributing drivers';
export const DATA_SOURCES_READY_TEXT = 'Data sources ready, now under review';
export const PREPARING_SOURCES_TEXT = 'CloverpopAI is preparing the data sources';
export const REFINE_TEXT = 'CloverpopAI is refining the results based on additional instructions';

export const getDotsLoaderText = (aiUseOnlyDrivers, countAnsweredChildren, reviewingText, index) => {
  if (index > 0) return REFINE_TEXT;
  if (aiUseOnlyDrivers) return REVIEWING_DRIVERS_TEXT;
  return countAnsweredChildren > 1 ? reviewingText : DATA_SOURCES_READY_TEXT;
};

export const getInitContext = (type, decision, driverData) => {
  switch (type) {
    case 'recommendation':
      return decision.recommendation?.chat_gpt_queries || [];
    case 'decision':
      return decision.chat_gpt_queries || [];
    case 'driver':
      return driverData.driver?.chat_gpt_queries || [];
    default:
      return [];
  }
};

export const gptApiSummarizeRequest = (chatGptDriverSummarizeRequest, setLoaded, setError, context, refineMessage,
                                       setContext, type, objectSlug, setRefineMessage, setLoadedVs, only_assigned_drivers,
                                       decisionSlug) => {
  setLoadedVs(true)
  setLoaded(false)
  const callback = (success) => {
    if (success) {
      setLoaded(true)
      setRefineMessage('')
    } else {
      setLoaded(true)
      setError(AI_ERROR_MESSAGE)
    }
  }

  if (isPresent(refineMessage)) {
    context.push({role: 'user', content: refineMessage})
  }
  const data = {context, generated_for: {type, slug: objectSlug}, only_assigned_drivers}
  chatGptDriverSummarizeRequest(data, callback, setContext, decisionSlug)
}

export const apiAiResponseRequest = (aiResponseRequest, setLoaded, setError, context, refineMessage,
                                     setContext, objectSlug, setRefineMessage, type,
                                     vectorStoreUploadFilesRequest, setLoadedVs, only_assigned_drivers, decisionSlug) => {
  setLoadedVs(false)
  const callback = (success) => {
    setLoaded(true)
    success ? setRefineMessage('') : setError(AI_ERROR_MESSAGE)
  }

  if (isPresent(refineMessage)) {
    context.push({role: 'user', content: refineMessage})
  }
  const generated_for = {type, slug: objectSlug}
  vectorStoreUploadFilesRequest({generated_for}, (success) => {
    setLoadedVs(true)
    setLoaded(false)
    if (success) {
      const data = {context, generated_for, only_assigned_drivers}
      aiResponseRequest(data, callback, setContext, decisionSlug)
    }
  },
  decisionSlug)
}

export const parseAssistantContent = (content) => {
  try {
    return JSON.parse(content || '{}');
  } catch (e) {
    console.error('Error parsing JSON:', e);
    return { short_summary: '', response: '', file_analysis: '' };
  }
};