import {
  LOAD_ORG_CATEGORIES_DATA,
  RESET_ORG_CATEGORIES,
  UPDATE_ORG_CATEGORIES_DATA,
  UPDATE_ORG_CATEGORIES_FAILED
} from "./types";

const initialState = {
    error: '',
    loaded: false,
    loading: false,
    available_categories: [],
    selected_decision_categories: [],
    selected_template_categories: []
};

export function orgCategoriesReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case LOAD_ORG_CATEGORIES_DATA: {
            return {
                ...state,
                ...action.payload,
                error: ''
            };
        }
        case UPDATE_ORG_CATEGORIES_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }
        case UPDATE_ORG_CATEGORIES_FAILED: {
            return {
                ...state,
                loading: false,
                ...action.payload
            };
        }
        case RESET_ORG_CATEGORIES: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
}
