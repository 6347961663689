import React from 'react';
import { connect } from "react-redux";
import { addShare } from "../../store/share/actions";
import { closeModal, openModal } from "../../store/modals/actions";
import { isDecider, isDeciderReadOnly } from "../../helpers/user_helpers";
import ShareDropdownModal, { showShareDropdownModal } from "./ShareDropdownModal";

const TreeShareModal = ({
                          current_user,
                          modal, closeModal,
                          addShare
                        }) => {
  if (!isDecider(current_user) && !isDeciderReadOnly(current_user)) return null;

  const onSubmit = (items, invites, data, callback) => {
    addShare(items, invites, data, (success) => {
      callback(success);
      if (success) closeModal()
    })
  }

  return <ShareDropdownModal shown={showShareDropdownModal(modal)} onClose={closeModal} onSubmit={onSubmit} />
}

export const mapStateToProps = ({ modal, current_user }) => ({ modal, current_user });

export default connect(mapStateToProps, { addShare, openModal, closeModal })(TreeShareModal);
