import { CLOSE_MODAL, LOAD_MODAL_DATA, RESET_MODAL, UPDATE_FAILED, UPDATE_MODAL_DATA } from "./types";
import EntryPoint from "../../EntryPoint";
import {
    createDataSource,
    destroyDataSource,
    postDriverCommentData,
    removeDriverCommentData,
    replaceDataSource,
    updateDataSource,
    updateDriverCommentData,
    updateDriverData, updateRecommendationData,
    updateTemplateDataRequest,
    updateTreeDataRequest,
    createDecisionReportData,
    updateTreeFlowRequest,
    assignDraftDecisionRequest, addSourceToDecisionRequest, createDecisionForecastScenarioData
} from "../../utils/Api";
import {failedResponseHandler, isResponseFailed, updateDecisionDataInStores} from "../../helpers/store_helpers";
import { loadPlaybookNotesSuccess } from "../playbook_notes/actions";
import { isBlank, isPresent } from "../../helpers/common";
import {
  transferDecisionAndDriversAndSourcesFromModal,
  transferDecisionFromModal,
  transferDriversFromModal,
  transferSourcesAndDriversFromModal,
  transferTemplateFromModal,
  isRecommendationEntryModal,
  isDecisionEntryModal
} from "../tree/modal_actions";
import {updateTemplateData, loadTemplateSuccess} from "../template/common_actions";
import {updateGptQueries} from "../sidebar/gpt_actions";
import {updateSidebarDecisionData} from "../sidebar/actions";
import {reloadContacts} from "../contacts/actions";
import {updateTreeData} from "../tree/common_actions";

export function updateTemplate(data, callback = () => {}) {
    return (dispatch, getState) => {
        const { objectSlug } = EntryPoint.instance;
        updateTemplateDataRequest(objectSlug, data).then(response => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure }, callback);

            const { data } = response;
            const { template } = data;
            dispatch(loadTemplateSuccess(template));
            dispatch(loadPlaybookNotesSuccess({ ...template.playbook_notes }));
            if(getState().modal.shown) {
                dispatch(updateModal({decision: template}));
            }  else {
                dispatch(transferTemplateFromModal({template}))
            }
            callback(true)
        })
    }
}

export const updateDecision = (data, callback = () => {}, decisionSlug = '') => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    updateTreeDataRequest({ objectSlug: decisionSlug || objectSlug, data })
      .then(updateDecisionAndDriversOnResponse(dispatch, getState, callback, true, true))
}

export const assignDraftDecision = (slug, data, callback = () => {}) => (dispatch) => {
    const { controllerName, objectSlug } = EntryPoint.instance;
    assignDraftDecisionRequest({ controllerName, objectSlug, slug, data }).then(response => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure }, callback)

        callback(true)
    })
}

export const updateDecisionFlow = (data, callback = () => {}) => (dispatch, getState) => {
    const { objectSlug } = EntryPoint.instance;
    updateTreeFlowRequest({ objectSlug, data }).then(response => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure }, callback);

        const { data } = response;
        const { decision } = data;
        if(getState().modal.shown) {
            dispatch(updateModal({decision: decision}));
        }  else {
            dispatch(transferDecisionFromModal({decision}))
        }
        callback(true)
    })
}

export const updateRecommendation = (data, callback = () => {}, decisionSlug = '') => (dispatch, getState) => {
    const { objectSlug, recommendationSlug } = EntryPoint.instance;
    updateRecommendationData({
        objectSlug: decisionSlug || objectSlug,
        recommendationSlug: data.recommendation_slug || recommendationSlug,
        data
    }).then(updateDecisionAndDriversOnResponse(dispatch, getState, callback));
}

// Model Drivers
export const updateDriver = (data, callback = () => {}) => (dispatch, getState) => {
    const slug = data.slug;
    if (isBlank(slug)) return;

    const { objectSlug, controllerName } = EntryPoint.instance;
    updateDriverData({ controllerName, objectSlug, slug, data }).then(response => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure }, callback);

        const { data } = response;
        const { drivers, users, invites, template, decision_chat_gpt_queries, recommendation_chat_gpt_queries } = data;
        const decision = { ...getState().decision, users, invites }

        isPresent(template) && dispatch(updateTemplateData({ ...template }))
        updateModalCollaborators(dispatch, getState, data);
        updateModalDriverData(dispatch, getState, data);
        dispatch(updateTreeData({ drivers }));
        updateSidebarDecisionData(dispatch, getState, decision);
        updateGptQueries(dispatch, getState, decision_chat_gpt_queries, recommendation_chat_gpt_queries)
        dispatch(reloadContacts());
        callback(true)
    })
}

// Model Driver Comments
export const postUserDriverComment = (driver, data, callback) => (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    postDriverCommentData({ controllerName, objectSlug, driverSlug: driver.slug, data })
      .then(response => {
          if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure }, callback)

          const { data } = response;
          updateModalCollaborators(dispatch, getState, data)
          if (data?.invites)
              updateDecisionDataInStores(dispatch, getState, { invites: data.invites, users: data.users })
          updateModalDriverData(dispatch, getState, data);
          callback(true);
      })
}
export const updateUserDriverComment = (driver, comment, data, callback) => (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    updateDriverCommentData({ controllerName, objectSlug, driverSlug: driver.slug, slug: comment.id, data })
      .then(response => {
          if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure }, callback)

          const { data } = response;
          updateModalCollaborators(dispatch, getState, data)
          updateModalDriverData(dispatch, getState, data);
          callback(true);
      })
}
export const deleteUserDriverComment = (driver, comment, callback) => (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    removeDriverCommentData({ controllerName, objectSlug, driverSlug: driver.slug, slug: comment.id })
      .then(response => {
          if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure }, callback)

          const { data } = response;
          updateModalDriverData(dispatch, getState, data);
          callback(true);
      })
}

export function replaceSource(slug, data, config = {}, callback = () => {}, _) {
    return (dispatch, getState) => {
        const { objectSlug, controllerName } = EntryPoint.instance;
        replaceDataSource({ controllerName, objectSlug, slug, data, config }).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure }, callback)

            const { data } = response;
            const { data_sources, drivers } = data;
            if(getState().modal.shown) {
                dispatch(updateModal({data_sources, drivers}));
            }  else {
                dispatch(transferSourcesAndDriversFromModal({data_sources, drivers}))
            }
            callback(true);
        })
    }
}

// Attach Report to Decision
export function attachReport(reportSlug, data, _) {
    return (dispatch, getState) => {
        const { objectSlug, controllerName } = EntryPoint.instance;

        createDecisionReportData({ controllerName, objectSlug, reportSlug }, data).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure });

            const { data } = response;
            const { data_sources, drivers } = data;
            if(getState().modal.shown) {
                dispatch(updateModal({data_sources, drivers}));
            }  else {
                dispatch(transferSourcesAndDriversFromModal({data_sources, drivers}))
            }
        })
    }
}

// Attach Forecast Scenario to Decision
export const attachScenario = (scenarioId, data, _) => (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    createDecisionForecastScenarioData({ controllerName, objectSlug }, { ...data, id: scenarioId }).then((response) => {
        if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure });

        const { data } = response;
        const { data_sources, drivers } = data;
        if(getState().modal.shown) {
            dispatch(updateModal({data_sources, drivers}));
        }  else {
            dispatch(transferSourcesAndDriversFromModal({data_sources, drivers}))
        }
    })
}

// Model Sources
export function updateSource(slug, data, _) {
    return (dispatch, getState) => {
        const { objectSlug, controllerName } = EntryPoint.instance;
        updateDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure });

            const { data } = response;
            const { data_sources, drivers } = data;
            if(getState().modal.shown) {
                dispatch(updateModal({data_sources, drivers}));
            }  else {
                dispatch(transferSourcesAndDriversFromModal({data_sources, drivers}))
            }
        })
    }
}
export function createSource(data, config = {}, callback = () => {}, _) {
    return (dispatch, getState) => {
        const { objectSlug, controllerName } = EntryPoint.instance;
        createDataSource({ controllerName, objectSlug, data, config }).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure }, callback)

            const { data } = response;
            const { data_sources, drivers } = data;
            if(getState().modal.shown) {
                dispatch(updateModal({data_sources, drivers}));
            }  else {
                dispatch(transferSourcesAndDriversFromModal({data_sources, drivers}))
            }
            callback(true);
        })
    }
}
export function destroySource(slug, data, _) {
    return (dispatch, getState) => {
        const { objectSlug, controllerName } = EntryPoint.instance;
        destroyDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure });

            const { data } = response;
            const { data_sources, drivers } = data;
            if(getState().modal.shown) {
                dispatch(updateModal({data_sources, drivers}));
            }  else {
                dispatch(transferSourcesAndDriversFromModal({data_sources, drivers}))
            }
        })
    }
}

export function addSourceToDecision(slug, setSlug, data) {
    return (dispatch, getState) => {
        addSourceToDecisionRequest({ objectSlug: setSlug, slug, data }).then((response) => {
            if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure });

            const { data } = response;
            const { data_sources, drivers } = data;
            if(getState().modal.shown) {
                dispatch(updateModal({data_sources, drivers}));
            }  else {
                dispatch(transferSourcesAndDriversFromModal({data_sources, drivers}))
            }
        })
    }
}

export function saveUploadingSources(sources) {
    return (dispatch) => {
        dispatch(openModal({ uploading_sources: sources }));
    }
}

// Model helpers
export const openModal = (data) => (dispatch) => {
    dispatch({
        type: LOAD_MODAL_DATA,
        payload: {
            ...data
        }
    })
};

export const updateModal = (data) => (dispatch) => {
    dispatch({
        type: UPDATE_MODAL_DATA,
        payload: {
            ...data
        }
    })
};

export function transferDataAndCloseModal() {
    return (dispatch, getState) => {
        dispatch(closeModal());
        setTimeout(() => {
            const modal = getState().modal;
            if(isDecisionEntryModal(modal)) {
                dispatch(transferDecisionFromModal(modal, true))
                dispatch(transferDriversFromModal(modal));
            } else if(modal.type === 'DecisionAssignModal') {
                dispatch(transferDecisionFromModal(modal, true))
            } else if(modal.type === 'RecommendationAssignModal') {
                dispatch(transferDecisionFromModal(modal, true))
            } else if (isRecommendationEntryModal(modal)) {
                dispatch(transferDecisionFromModal(modal, true))
                dispatch(transferDriversFromModal(modal));
            } else if (modal.type === 'AssignDecisionModal') {
                dispatch(transferDecisionFromModal(modal))
            } else if (modal.type === 'TemplateEntryModal') {
                dispatch(transferTemplateFromModal({ template: modal.decision }))
            } else if (modal.type === 'TreePanelTemplateEntryModal') {
                dispatch(transferTemplateFromModal({ template: modal.decision }))
            } else if(modal.type === 'DriverShowModal') {
                dispatch(transferDecisionAndDriversAndSourcesFromModal(modal))
            } else if(modal.type === 'DriverDetailsModal') {
                dispatch(transferDecisionAndDriversAndSourcesFromModal(modal))
            } else if(modal.type === 'TreePanelDriverDetailsModal') {
                dispatch(transferDecisionAndDriversAndSourcesFromModal(modal))
            } else if(modal.type === 'TreePanelAddDriverDetailsModal') {
                dispatch(transferDecisionAndDriversAndSourcesFromModal(modal))
            } else if (modal.type === 'DSightDriverModal') {
                dispatch(transferDecisionAndDriversAndSourcesFromModal(modal))
            } else if (modal.type === 'DecisionAssignModal') {
                dispatch(transferDecisionAndDriversAndSourcesFromModal(modal))
            } else if (modal.type === 'RecommendationAssignModal') {
                dispatch(transferDecisionAndDriversAndSourcesFromModal(modal))
            } else if (modal.type === 'DriverAssignModal') {
                dispatch(transferDecisionAndDriversAndSourcesFromModal(modal))
            } else if (modal.type === 'WizardDriverDetailsModal') {
                dispatch(transferDecisionAndDriversAndSourcesFromModal(modal))
            } else if (modal.type === 'WizardAddDriverDetailsModal') {
                dispatch(transferDecisionAndDriversAndSourcesFromModal(modal))
            } else if (modal.type === 'FollowupResultsModal') {
                dispatch(transferDecisionFromModal(modal))
            } else if (modal.type === 'RefineChatGptModal') {
                dispatch(transferDecisionFromModal(modal))
            } else if (modal.type === 'DecisionPollModal') {
                dispatch(transferDecisionFromModal(modal))
            }
            dispatch(resetModal());
        }, 50);
    };
}

const updateDecisionAndDriversOnResponse = (dispatch, getState, callback = () => {}, updatePlaybookNotes = false, transferToDecisionSet = false) => (response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadModalUpdateFailure }, callback);

    const { data } = response;
    const { decision, drivers } = data;
    if(updatePlaybookNotes) dispatch(loadPlaybookNotesSuccess({ ...decision.playbook_notes }));
    if(getState().modal.shown) {
        dispatch(updateModal({ decision, drivers }));
    }  else {
        dispatch(transferDecisionFromModal({ decision }, false, transferToDecisionSet ? isPresent(decision.decision_set_slug) : false));
        dispatch(transferDriversFromModal({ drivers }));
    }
    callback(true, decision);
};

export function closeAndResetDriverDetailsModal(driverSlug) {
    return (dispatch, getState) => {
        if(getState().modal.slug === driverSlug &&
          (getState().modal.type === 'DriverDetailsModal' || getState().modal.type === 'TreePanelDriverDetailsModal')) {
            dispatch(closeModal())
            dispatch(resetModal())
        }
    };
}
export function closeModal() {
    return (dispatch) => {
        dispatch({
            type: CLOSE_MODAL
        })
    };
}

export function resetModal() {
    return (dispatch) => {
        dispatch({
            type: RESET_MODAL
        })
    };
}

export const loadModalUpdateFailure = error => ({
    type: UPDATE_FAILED,
    payload: {
        error
    }
});

const updateModalDriverData = (dispatch, getState, data) => {
    const { drivers } = data;
    if(getState().modal.shown) {
        dispatch(updateModal({drivers}));
    }  else {
        dispatch(transferDriversFromModal({drivers}))
    }
}
const updateModalCollaborators = (dispatch, getState, data) => {
    const { users, invites } = data;
    const decision = { ...getState().decision, users, invites }
    if (isPresent(users)) dispatch(updateModal({ decision }))
}
