import React, { useState, useRef, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { loadContacts } from "../store/contacts/actions";
import { closeModal } from "../store/modals/actions";
import {
  loadForecastSimulator,
  loadForecastSimulatorDBenchmarkScenario,
  loadTableCells,
  loadOpenedGroups,
  updateScenarioData
} from "../store/forecast_simulator_scenario/actions";
import { checkLoadingEffect, isStoreLoading } from "../helpers/callbacks_helpers";
import { prepareAllOptionsArray, useWhiteBackgroundEffect } from "./helpers/helpers";
import { Loader } from "../common/Loader";
import TabManager from "./TabManager";
import Wrapper from "./Wrapper";
import { isBlank, isPresent } from "../helpers/common";
import { TIME_SCALES } from "./helpers/ag_grid_vars";
import { ForecastConfig } from "../models/forecast/ForecastConfig";
import { ForecastScenario } from "../models/forecast/ForecastScenario";
import Sidebars from "./Sidebars";
import { updateEntryPointData } from "../EntryPoint";

export const TABS = {
  table: 'table',
  charts: 'charts',
  decomposition: 'decomposition'
}

export const TAB_TITLES = {
  table: 'Table',
  charts: 'Charts',
  decomposition: 'Decomposition'
}

const ForecastSimulatorScenario = ({ match, contactsData, forecast_simulator_scenario, modal,
                                     loadContacts, closeModal, updateScenarioData,
                                     loadForecastSimulator, loadForecastSimulatorDBenchmarkScenario,
                                     loadTableCells, loadOpenedGroups
                                   }) => {
  const [activeTab, setActiveTab] = useState(TABS.table);
  const [timeScale, setTimeScale] = useState(TIME_SCALES[0]);
  const [cachedAgGridRows, setCachedAgGridRows] = useState({});
  const gridRef = useRef();
  const { id: scenario_id } = useParams();
  useEffect(() => updateEntryPointData(match, 'scenarios'), [match?.params?.id]);
  checkLoadingEffect(contactsData, loadContacts);
  useEffect(() => {
    if (!forecast_simulator_scenario.loaded) return;
    if (isBlank(forecast_simulator_scenario.scenario_id) || isBlank(scenario_id)) return;

    if (scenario_id !== forecast_simulator_scenario.scenario_id) {
      const updateData = { loaded: false, scenario_loaded: false, scenario_error: false, table_cells_loaded: false, opened_groups_loaded: false }
      if (scenario_id === forecast_simulator_scenario.benchmark_id) updateData.benchmark = null
      updateScenarioData(updateData)
    }
  }, [scenario_id]);
  useEffect(() => {
    const getParams = new URLSearchParams(document.location.search)
    const newActiveTab = getParams.get('activeTab')
    if (isPresent(newActiveTab) && newActiveTab !== activeTab) { setActiveTab(newActiveTab) }
  }, [document.location.search]);
  checkLoadingEffect(forecast_simulator_scenario, () => loadForecastSimulator(scenario_id), { another_conditions: [scenario_id] });
  useEffect(() => {
    if (!forecast_simulator_scenario.loaded) return;
    if (isPresent(forecast_simulator_scenario.scenario_error)) return;
    if (forecast_simulator_scenario.scenario_loading) return;

    if (isPresent(scenario_id)) {
      loadForecastSimulator(scenario_id)
    }
  }, [forecast_simulator_scenario.scenario_loaded, forecast_simulator_scenario.scenario_loading, scenario_id]);
  useEffect(() => {
    if (!forecast_simulator_scenario.loaded) return;
    if (!forecast_simulator_scenario.scenario_loaded) return;
    if (isBlank(forecast_simulator_scenario.scenario_id) || forecast_simulator_scenario.benchmark_loading) return;

    if (!forecast_simulator_scenario.table_cells_loaded) {
      loadTableCells(forecast_simulator_scenario.scenario_id);
    }
    if(!forecast_simulator_scenario.opened_groups_loaded) {
      loadOpenedGroups(forecast_simulator_scenario.scenario_id);
    }
  }, [
    forecast_simulator_scenario.scenario_loaded, forecast_simulator_scenario.benchmark_loading,
    forecast_simulator_scenario.table_cells_loaded, forecast_simulator_scenario.opened_groups_loaded
  ]);
  useEffect(() => {
    if (isPresent(forecast_simulator_scenario.benchmark_error)) return;
    if (!forecast_simulator_scenario.loaded) return;
    if (isBlank(forecast_simulator_scenario.scenario_id) || forecast_simulator_scenario.benchmark_loading) return;

    if (isBlank(forecast_simulator_scenario.benchmark)) {
      const allScenarios = prepareAllOptionsArray(forecast_simulator_scenario.config_scenarios)
      const viewingBenchmark =  forecast_simulator_scenario.benchmark_id || forecast_simulator_scenario.scenario?.view_options?.benchmark_id;
      if (viewingBenchmark !== scenario_id && allScenarios.some(hash => hash.id === viewingBenchmark && isBlank(hash.deleted_at))) {
        return loadForecastSimulatorDBenchmarkScenario(viewingBenchmark)
      }
      const benchmarkToLoad = allScenarios.find(hash => isPresent(hash.default_scenario))?.id;
      if (isPresent(benchmarkToLoad)) {
        loadForecastSimulatorDBenchmarkScenario(benchmarkToLoad)
      }
    }
  }, [forecast_simulator_scenario.loaded, forecast_simulator_scenario.benchmark_loading]);
  useWhiteBackgroundEffect();

  const config = useMemo(() => {
      if (isBlank(forecast_simulator_scenario.config)) return null;

      return new ForecastConfig(forecast_simulator_scenario.config)
    }, [forecast_simulator_scenario.config]
  )
  const forecastScenario = useMemo(() => {
      if (isBlank(forecast_simulator_scenario.scenario)) return null;
      if (!forecast_simulator_scenario.table_cells_loaded) return null;
      if (!forecast_simulator_scenario.opened_groups_loaded) return null;

      return new ForecastScenario(
        forecast_simulator_scenario.scenario,
        config,
        forecast_simulator_scenario.scenario?.view_options?.timeScale,
        cachedAgGridRows[forecast_simulator_scenario.scenario.id]
      )
    }, [forecast_simulator_scenario.scenario, forecast_simulator_scenario.table_cells_loaded, forecast_simulator_scenario.opened_groups_loaded, config]
  )
  const forecastBenchmarkScenario = useMemo(() => {
    if (isBlank(forecast_simulator_scenario.scenario)) return null;
    if (isBlank(forecast_simulator_scenario.benchmark)) return null;

    return new ForecastScenario(forecast_simulator_scenario.benchmark, config, timeScale);
  }, [
    forecast_simulator_scenario.scenario,
    forecast_simulator_scenario.benchmark,
    forecast_simulator_scenario.benchmark_id,
    forecast_simulator_scenario.benchmark_loaded,
    forecast_simulator_scenario.benchmark_loading,
    config
  ])

  useEffect(() => {
    if(isPresent(forecastScenario) && isPresent(forecastScenario.viewOptions.timeScale)) {
      setTimeScale(forecastScenario.viewOptions.timeScale);
      forecastScenario.setTimeScale(forecastScenario.viewOptions.timeScale);
      forecastBenchmarkScenario?.setTimeScale(forecastScenario.viewOptions.timeScale);
    }
  }, [forecastScenario?.viewOptions?.timeScale, forecastScenario?.viewOptions?.from, forecastScenario?.viewOptions?.to])

  const scenarioOpts = { config, forecastScenario, forecastBenchmarkScenario, timeScale, cachedAgGridRows, setCachedAgGridRows }
  const wrapperOpts = { modal, closeModal, activeTab, gridRef, forecastScenario };

  if (isStoreLoading(forecast_simulator_scenario) || isStoreLoading(forecast_simulator_scenario, 'scenario') ||
    isStoreLoading(forecast_simulator_scenario, 'benchmark') ||
    isBlank(forecastScenario) || isBlank(forecastBenchmarkScenario)) {
    return <Wrapper {...wrapperOpts}>
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper {...wrapperOpts} scenarioIsLoaded={true}>
      <TabManager activeTab={activeTab} gridRef={gridRef} {...scenarioOpts} />
      <Sidebars activeTab={activeTab} gridRef={gridRef} { ...scenarioOpts } />
    </Wrapper>
  }
}

const mapStateToProps = ({ contacts, forecast_simulator_scenario, modal }) => ({
  contactsData: contacts, forecast_simulator_scenario, modal
});
export default connect(mapStateToProps, {
  loadContacts, closeModal, updateScenarioData,
  loadForecastSimulator, loadForecastSimulatorDBenchmarkScenario, loadTableCells, loadOpenedGroups
})(ForecastSimulatorScenario);
