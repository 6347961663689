import React, { useRef, useState, useEffect } from 'react';
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Menu from "./SearchDecisionFiltersMenu";
import { useRootClose } from "react-overlays";
import { isDefaultCategoriesFilters, isMoreFilters } from '../../../helpers/filter_helpers';
import Toggle from "../../../common/Toggle";
import CategoriesFilterMenu from "./SearchDecisionFilters/CategoriesFilterMenu";
import { MAX_SMALL_SCREEN_WIDTH } from "../../../store";

const TOGGLE_OPTIONS = [
  {
    value: 'Grid',
    body: <>
      <i className="text-primary fas fa-grid fa-lg" />
      <span className="fw-bolder ps-1">Grid</span>
    </>
  },
  {
    value: 'List',
    body: <>
      <i className="text-primary fas fa-list-ul fa-lg" />
      <span className="fw-bolder ps-1">List</span>
    </>
  },
];

export const DEFAULT_FILTER_TOGGLE_VALUE = TOGGLE_OPTIONS[0].value;

export const SearchDecisionFilters = ({ home, view, setView, decisionSetPresent }) => {
  const [show, setShow] = useState(false);
  const [showCategoriesFilter, setShowCategoriesFilter] = useState(false);
  const [saveMenu, setSaveMenu] = useState(false);
  const showBubble = !isMoreFilters(home);
  const showCategoriesFilterBubble = !isDefaultCategoriesFilters(home);
  const refFilter = useRef();
  const refCategoriesFilter = useRef();
  const onRootCloseStart = () => {
    setSaveMenu(true);
  };
  const onRootCloseFinish = () => {
    setSaveMenu(false);
    setShow(false);
    setShowCategoriesFilter(false);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < MAX_SMALL_SCREEN_WIDTH) setView(DEFAULT_FILTER_TOGGLE_VALUE)
    };
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useRootClose(refFilter, onRootCloseStart, {disabled: !show,});
  useRootClose(refCategoriesFilter, onRootCloseStart, {disabled: !showCategoriesFilter});
  const categoriesFilterClick = () => setTimeout(() => setShowCategoriesFilter(!showCategoriesFilter), 100)
  const moreFiltersClick = () => setTimeout(() => setShow(!show), 100)

  return <div className="d-flex">
    <div className="d-flex">
      <div className="search-decisions-filter">
        <Button
          className={`btn form-control rounded text-dark fw-normal dropdown-toggle border-0 ${showCategoriesFilterBubble ? 'bg-secondary' : 'bg-white'}`}
          onClick={categoriesFilterClick}>
          <span className={`${showCategoriesFilterBubble ? 'orange-bubble' : ''}`}>Categories</span>
        </Button>
        {showCategoriesFilter && (
          <div ref={refCategoriesFilter} className="filter-menu">
            <CategoriesFilterMenu {...{ saveMenu, onSave: onRootCloseFinish }}/>
          </div>
        )}
      </div>
      <div className="search-decisions-filter ms-2">
        <Button
          className={`btn form-control rounded text-dark fw-normal dropdown-toggle border-0 ${showBubble ? 'bg-secondary' : 'bg-white'}`}
          onClick={moreFiltersClick}>
          <span className={`${showBubble ? 'orange-bubble' : ''}`}>More filters</span>
        </Button>
        {show && (
          <div ref={refFilter} className="filter-menu more-filter">
            <Menu saveMenu={saveMenu} onSave={onRootCloseFinish}/>
          </div>
        )}
      </div>
    </div>
    <div className="d-none d-md-block ms-2">
      {
        !decisionSetPresent && <Toggle
        selectedItem={view}
        onClick={setView}
        items={TOGGLE_OPTIONS}/>
      }
    </div>
  </div>;
}

const mapStateToProps = ({home}) => ({home});
export default connect(mapStateToProps)(SearchDecisionFilters);
