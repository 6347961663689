import React, {Fragment} from 'react';
import { SourceableTypes } from "../../../../models/data_source";
import { isBlank, isPresent, qSortArray } from "../../../../helpers/common";
import { BorderedFilterStyle } from "../../../../tree_wizard/styles/choice_entry_styles";
import Select from "react-select";

const selectPlaceholder = ({
                             orgReports = [], orgForecastScenarios = [],
                             dataSources = [], decisionSetSources = []
                           }) => {
  if (isPresent(orgReports) && isPresent(orgForecastScenarios) && (isPresent(dataSources) || isPresent(decisionSetSources))) {
    return 'Add an existing data source, forecast simulator scenario or report';
  }
  if (isPresent(orgReports) && isPresent(orgForecastScenarios)) {
    return 'Add a forecast simulator scenario or report';
  }
  if (isPresent(orgForecastScenarios) && (isPresent(dataSources) || isPresent(decisionSetSources))) {
    return 'Add an existing data source or forecast simulator scenario';
  }
  if (isPresent(orgReports) && (isPresent(dataSources) || isPresent(decisionSetSources))) {
    return 'Add an existing data source or report';
  }
  if (isPresent(orgReports)) {
    return 'Add a report';
  }
  if (isPresent(orgForecastScenarios)) {
    return 'Add a forecast simulator scenario';
  }
  if ((isPresent(dataSources) || isPresent(decisionSetSources))) {
    return 'Add an existing data source';
  }
}

export default ({
                  selectedSource,
                  onSelectSource, onSelectReport, onSelectScenario,
                  orgReports = [], orgForecastScenarios = [],
                  onSelectSetSource,
                  dataSources = [], decisionSetSources = []
                }) => {
  const combinedSources =
    [
      ...dataSources.map(h => ({ ...h, sourceable_type: SourceableTypes.DECISION })),
      ...decisionSetSources.map(h => ({ ...h, sourceable_type: SourceableTypes.DECISION_SET })),
      ...orgReports.map(h => ({ ...h, sourceable_type: SourceableTypes.REPORT })),
      ...orgForecastScenarios.flatMap(scenario => [
        {
          ...scenario, sourceable_type: SourceableTypes.FORECAST_SCENARIO,
          isDisabled: isPresent(scenario.default_scenario) || isPresent(scenario.deleted_at)
        },
        ...(scenario.actualized || []).map(copy => ({
          ...copy, sourceable_type: SourceableTypes.FORECAST_SCENARIO,
          icon: <i className={`fa-solid fa-lock icon fa-sm ms-2 me-1`} />
        }))
      ])
    ]

  if (isBlank(combinedSources)) return <></>

  const list = qSortArray(combinedSources, false, d => d.created_at)
  const decisionOrSetOption = (option) => [SourceableTypes.DECISION, SourceableTypes.DECISION_SET].includes(option.sourceable_type)
  const onChange = (option) => {
    switch (option.sourceable_type) {
      case SourceableTypes.FORECAST_SCENARIO:
        onSelectScenario(option)
        break;
      case SourceableTypes.DECISION_SET:
        onSelectSetSource(option)
        break;
      case SourceableTypes.DECISION:
        onSelectSource(option)
        break;
      case SourceableTypes.REPORT:
        onSelectReport(option)
        break;
    }
  }

  return <div className="existing-source-dropdown">
    <Select
      value={selectedSource}
      onChange={onChange}
      components={{IndicatorSeparator: () => null}}
      styles={BorderedFilterStyle}
      placeholder={selectPlaceholder({ orgReports, orgForecastScenarios, dataSources, decisionSetSources })}
      options={list.map(source => ({
        isDisabled: source.isDisabled || false,
        value: source.slug || source.id,
        label: <span style={{wordBreak: 'break-word'}}>
          {source.icon}
          {
            decisionOrSetOption(source) ? source.title || source.link_url : source.display_name || source.name
          }
        </span>,
        sourceable_type: source.sourceable_type })
      )}/>
  </div>
}
