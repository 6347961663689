import React, { Fragment } from 'react'
import SearchRow from "../homepage/tabs/helpers/SearchRow";
import {BaseDropdownBtn} from "./BaseHamburgerBtn";
import {BtnDropdownToggleInlineShadowText} from "./dropdowns";
import { isPresent } from "../helpers/common";

const actualizedTitle = (display_name, active = false) =>
  <>
    <i className={`fa-solid fa-lock ${active ? 'text-white' : 'text-gray'} fa-sm ms-2 me-1`} />
    {display_name}
  </>

export default ({
                  selectedOption,
                  searchQuery, setSearchQuery,
                  active = false, disabled_value = null,
                  options, onClick = (_) => null,
                  subKey = ''
                }) =>
  <BtnDropdownToggleInlineShadowText bsPrefix="text-dark fw-normal pe-0 h-auto"
                                     title={selectedOption || ''}>
    <div className="px-2 lh-base">
      <SearchRow {...{ searchQuery, setSearchQuery }} bordered hideResults
                 rowStyles={'pb-1'} placeholder={'Search scenarios'}
                 resetSearchAction={() => setSearchQuery('')} />
    </div>
    <div className="dropdown-menu-scroll">
      {
        options.map(({ id, display_name, filtered_actualized, actualized, deleted_at }) =>
          <Fragment key={`${subKey}-${id}`}>
            <BaseDropdownBtn eventKey={id} title={display_name}
                             bsPrefix={id === active ? 'active' : ''}
                             onClick={() => onClick(id)}
                             disabled={isPresent(deleted_at) || disabled_value === id} />
            {
              (filtered_actualized || actualized || []).map(({ id, display_name }) =>
                <BaseDropdownBtn key={`${subKey}-${id}`} eventKey={id}
                                 title={actualizedTitle(display_name, id === active)}
                                 onClick={() => onClick(id)}
                                 disabled={disabled_value === id}
                                 bsPrefix={`${id === active ? 'active' : ''}`} />
              )
            }
          </Fragment>

        )
      }
    </div>
  </BtnDropdownToggleInlineShadowText>
