import { useEffect } from "react";
import { BASIS_CHANGE_OPTIONS_KEYS } from "../side_panel/large_scale_input/BasisChange";
import {
  parseRowCellId,
  updateCells,
  rowCmus,
  relatedCell
} from "./common";
import { calcRootFromSimVs, calcSimVsValue } from "./ag_grid_formulas";
import { ROW_DRIVER_ID_KEY, CHANGE_TYPE_TABS } from "./ag_grid_vars";
import { isPresent } from "../../helpers/common";
import { MESSAGES, showOverlayWithMessage } from "./custom_loading_overlay";
import { selectedCmuCombinations } from "./scopes_helpers";
import { genYearAgoPeriod } from "../../models/forecast/ForecastTImeScale";

const buildNewValue = (changeType, amount, prevValue) =>
  changeType === CHANGE_TYPE_TABS.increaseDecreaseCurrentValue ? (Number(prevValue) + Number(amount)) : amount

const addToList = (list, childRowNode, prevValue, period, newValue) => {
  list.push({ node: { ...childRowNode, prevValue: prevValue }, colDef: { field: period.name, colId: period.id }, newValue: newValue })
}

const addToUpdateData = (delayedUpdateData, childRowNode, period, newValue) => {
  delayedUpdateData.push({ node: childRowNode, key: period.name, value: newValue })
}

const replaceCurrentValue = (list, rowNode, periods, factToUpdate, cmusCombinations, forecastScenario, changeType, amount, delayedUpdateData) => {
  const childRowNode = rowNode.allLeafChildren[0];
  const { driverId } = parseRowCellId(childRowNode.data[ROW_DRIVER_ID_KEY])
  const cmus = rowCmus(rowNode)
  if(matchFilters(factToUpdate, cmusCombinations, driverId, cmus)) {
    periods.forEach(period => {
      const newValue = buildNewValue(changeType, amount, childRowNode.data[period.name]);
      addToList(list, childRowNode, childRowNode.data[period.name], period, newValue);
      addToUpdateData(delayedUpdateData, childRowNode, period, newValue)
    })
  }
}

const matchFilters = (factToUpdate, cmusCombinations, driverId, cmus) => {
  return factToUpdate.id === Number(driverId) &&
    cmusCombinations.some(applyToCmuIds => applyToCmuIds.every(cmuId => cmus.includes(Number(cmuId))))
}

const handleCurrentBasisChange = (list, amount, period, row, driverId, changeType, editedCells, delayedUpdateData) => {
  const driverData = row.fetchDriverData({ id: driverId });
  const relatedEditedCell = relatedCell(editedCells, row.cmusDriverId, period.id);
  const actualPrevValue = relatedEditedCell?.value || driverData.base;
  const newValue = buildNewValue(changeType, amount, actualPrevValue);
  addToList(list, { id: row.cmusDriverId }, actualPrevValue, period, newValue);
  delayedUpdateData.push({ node: { id: row.cmusDriverId }, key: period.name, value: newValue })
}

const handleSimVsBenchmarkBasisChange = (list, amount, period, row, driverId, changeType, forecastBenchmarkScenario, editedCells, delayedUpdateData) => {
  const benchmarkRow = forecastBenchmarkScenario.findRowBy(row.cmus, period.id, row.selectedDriver.id);
  const benchmarkDriverData = benchmarkRow.fetchDriverData({ id: benchmarkRow.selectedDriver.id });
  const driverData = row.fetchDriverData({ id: driverId });
  const relatedEditedCell = relatedCell(editedCells, row.cmusDriverId, period.id);
  const actualPrevValue = relatedEditedCell?.value || driverData.base;
  const prevSimValue = calcSimVsValue(actualPrevValue, benchmarkDriverData.base);
  const newSimValue = buildNewValue(changeType, amount, prevSimValue);
  const newValue = calcRootFromSimVs(benchmarkDriverData.base, newSimValue);
  addToList(list, { id: row.cmusDriverId }, actualPrevValue, period, newValue);
  delayedUpdateData.push({ node: { id: row.cmusDriverId }, key: period.name, value: newValue });
}

const handleSimVsYaBasisChange = (list, amount, period, row, driverId, changeType, forecastScenario, allPeriods, editedCells, delayedUpdateData) => {
  const currentValue = forecastScenario.aggregateBy({
    cmus: row.cmus,
    period: period,
    driver: row.selectedDriver
  });
  const relatedEditedCell = relatedCell(editedCells, row.cmusDriverId, period.id);
  const actualPrevValue = relatedEditedCell?.value || currentValue;
  const prevYearValue = genYearAgoPeriod({ period, allowBlank: true });
  const prevSimVsYa = calcSimVsValue(actualPrevValue, prevYearValue);
  const newSimVsYaValue = buildNewValue(changeType, amount, prevSimVsYa);
  const newYaValue = calcRootFromSimVs(prevYearValue, newSimVsYaValue);
  addToList(list, { id: row.cmusDriverId }, prevYearValue, period, newYaValue);
  delayedUpdateData.push({ node: { id: row.cmusDriverId }, key: period.name, value: newYaValue });
};


const apply = ({
                 gridRef,
                 basisChange,
                 factToUpdate,
                 changeType,
                 amount,
                 periods,
                 cmusCombinations,
                 forecastScenario,
                 forecastBenchmarkScenario,
                 editedCells,
                 timeScale,
                 runModelCells,
                 updateTableCells,
                 callback
}) => {
  let list = [];
  let delayedUpdateData = [];
  const allPeriods = forecastScenario.allTimeScalePeriods(timeScale);
  const periodIds = periods.map(period => period.id);
  forecastScenario.rows.filter(row => periodIds.includes(row.attributes.tp_id)).forEach(row => {
    const driverId = row.selectedDriver?.id;
    const period = periods.find(period => period.id === row.attributes.tp_id);
    const cmus = row.cmus;
    if(matchFilters(factToUpdate, cmusCombinations, driverId, cmus)) {
      switch (basisChange) {
        case BASIS_CHANGE_OPTIONS_KEYS.current:
          handleCurrentBasisChange(list, amount, period, row, driverId, changeType, editedCells, delayedUpdateData)
          break;
        case BASIS_CHANGE_OPTIONS_KEYS.simVsBenchmark:
          handleSimVsBenchmarkBasisChange(list, amount, period, row, driverId, changeType, forecastBenchmarkScenario, editedCells, delayedUpdateData)
          break;
        case BASIS_CHANGE_OPTIONS_KEYS.simVsYearAgo:
          handleSimVsYaBasisChange(list, amount, period, row, driverId, changeType, forecastScenario, allPeriods, editedCells, delayedUpdateData)
          break;
      }
    }
  });
  updateCells(gridRef, editedCells, forecastScenario, runModelCells, list, timeScale, updateTableCells, callback, delayedUpdateData)
};

export const applyLargeScaleInput = ({ forecastScenario, largeScaleInput, ...opts }, callback) => {
  const { from, to, scopes, ...largeScaleInputOpts } = largeScaleInput;
  const timeScale = forecastScenario.editableTimeScaleKey;
  const periods = forecastScenario.periods({ from, to }, timeScale);
  const cmusCombinations = selectedCmuCombinations(scopes)
  const builtOpts = { periods, cmusCombinations, forecastScenario, timeScale }
  apply({
    ...opts,
    ...largeScaleInputOpts,
    ...builtOpts,
    callback
  });
};

export const useApplyLargeScaleInputEffect = ({ gridRef, forecast_simulator_scenario, updateScenarioData, ...opts }) => {
  useEffect(() => {
    if(isPresent(forecast_simulator_scenario.large_scale_input)) {
      showOverlayWithMessage(gridRef.current?.api, updateScenarioData, MESSAGES.updating_scenario);
      setTimeout(() => {
        applyLargeScaleInput({
            gridRef,
            largeScaleInput: forecast_simulator_scenario.large_scale_input,
            ...opts
          },
          () => updateScenarioData({ large_scale_input: {} }));
      }, 700);
    }
  }, [forecast_simulator_scenario.large_scale_input])
};
