import { RESET_SIDEBAR_DATA, SET_SIDEBAR_DATA } from "./types";

const initialState = {
    rightError: '',
    leftError: '',
    decisionDetailsSidebar: false,
    setSummarySidebar: false,
    setSectionsSidebar: false,
    setDecisionsSidebar: false,
    playbookSidebar: false,
    finalDecisionSidebar: false,
    discussionSidebar: false,
    helpSidebar: false,
    decisionInputSidebar: false,
    driverInputSidebar: false,
    recommendationInputSidebar: false,
    recommendationDetailsSidebar: false,
    dSightTreeSidebar: false,
    filtersSidebar: false,
    treeSidebar: false,
    decisionOrderSidebar: false,
    linkPanelSidebar: false,
    ratingsAndWeightsSidebar: false,
    pollPanelSidebar: false,
    largeScaleInputSidebar: false,
    importValuesSidebar: false,
    decision: null,
    driverSlug: null,
    drivers: [],
    data_sources: [],
    uploading_sources: [],
    maximizeIframe: false,
    trigger: null,
    loading: false,
    isOpened: false,
    treeMode: '',
    largeScaleInputSelectedDriver: null,
    gptDriverSlug: null,
    rationaleTimeStamp: '',
    driverAiResponseSidebar: false,
    recommendationAiResponseSidebar: false,
    decisionAiResponseSidebar: false,
    collaboratorsSidebar: false,
    dataSourcesSidebar: false,
    moreActionsSidebar: false
};

export function sidebarReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case SET_SIDEBAR_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }
        case RESET_SIDEBAR_DATA: {
            return {
                ...state,
                ...initialState
            };
        }
        default:
            return state;
    }
}
