import {
  preparedColDefs
} from "./ag_grid_col_defs";
import { ROW_DRIVER_ID_KEY } from "./ag_grid_vars";
import { getRowStyle, applyEditedCells, isGroupOpenByDefault, getAggFuncs } from "./ag_grid_helpers";
import { isPresent, isBlank } from "../../helpers/common";

const invalidParams = (forecastScenario, forecastBenchmarkScenario, config, timeScale, exportRows) =>
  isBlank(forecastScenario) || isBlank(forecastBenchmarkScenario) || isBlank(config) || isBlank(timeScale) || isBlank(exportRows);

export const agGridExcelInit = ({ forecast_simulator_scenario, excelGridRef, exportRows,
                                  config, forecastScenario, forecastBenchmarkScenario, timeScale,
                                  updateScenarioData }) => {
  if(invalidParams(forecastScenario, forecastBenchmarkScenario, config, timeScale, exportRows)) return {};

  const colDefs = preparedColDefs({ forecastBenchmarkScenario, forecastScenario, config, timeScale });
  const allRows = [...exportRows, ...forecastScenario.addedComparisonRows(exportRows)];
  const rowData = applyEditedCells(allRows, forecastScenario.actualEditedCells || [], timeScale);

  const columnTypes = {
    styledCAGRColumn: { cellStyle: () => {} },
    styledPeriodColumn: { cellStyle: () => {} },
    styledFactsColumn: { cellStyle: () => {} },
  };

  return {
    rowData,
    rowStyle: { background: '#FBFCFE' },
    columnDefs: colDefs,
    rowGroupPanelShow: 'never',
    rowGroupPanelSuppressSort: true,
    groupSuppressBlankHeader: true,
    groupAllowUnbalanced: true,
    reactiveCustomComponents: true,
    columnMenu: 'legacy',
    getRowStyle: (params) => getRowStyle(params, forecastScenario),
    columnTypes,
    getRowId: (params) => params.data[ROW_DRIVER_ID_KEY],
    aggFuncs: getAggFuncs(forecastScenario),
    isGroupOpenByDefault: (params) => isGroupOpenByDefault(params, forecastScenario),
    groupDisplayType: 'custom',
    suppressAggFuncInHeader: true,
    onRowDataUpdated: (event) => {
      if(forecast_simulator_scenario.export_table && !forecast_simulator_scenario.exporting_table && isPresent(rowData)) {
        excelGridRef.current.api.exportDataAsExcel();
        updateScenarioData({ exporting_table: true });
        setTimeout(() => {
          updateScenarioData({ exporting_table: false, export_table: false });
        }, 3000);
      }
    }
  };
};
