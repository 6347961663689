import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import { hideForecastSimulatorTab, TABS_URLS } from "../../helpers/home_helpers";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Switcher, { TABS } from "./forecast_simulator/Switcher";
import Search from "./forecast_simulator/Search";
import ScenariosCount from "./forecast_simulator/ScenariosCount";
import SortByCriteria, { SortByTypes } from "./search/SortByActivity";
import ScenarioItem from "./forecast_simulator/ScenarioItem";
import { Loader } from "../../common/Loader";
import { checkLoadingEffect } from "../../helpers/callbacks_helpers";
import { loadContacts } from "../../store/contacts/actions";
import { allContacts } from "../../helpers/filter_helpers";
import {
  needToLoadHomepageSection,
  loadForecastSimulatorData,
  isHomepageSectionLoading, createForecastSimulatorRecord
} from "../../store/homepage/actions";
import { isBlank, isPresent, qSortArray } from "../../helpers/common";
import { homeSearchState } from "./SearchSection";
import NullSearchResults from "./helpers/NullSearchResults";
import NewScenarioModal from "../modals/NewScenarioModal";
import { showAlert } from "../../store/alerts/actions";
import { DURATION } from "../../alerts";
import * as moment from "moment/moment";

const formatedDate = (h) => moment(h.finalized_at).format('DD MMM, yyyy')

const sortScenarios = (scenarios, sortOrder) => {
  if (isBlank(scenarios)) return [];

  if (sortOrder === SortByTypes.MOST_RECENT) {
    return qSortArray(scenarios, false, row => row.finalized_at || row.data_updated_at || row.data_created_at)
  } else if (sortOrder === SortByTypes.NEWEST) {
    return qSortArray(scenarios, false, row => row.data_created_at)
  } else if (sortOrder === SortByTypes.OLDEST) {
    return qSortArray(scenarios, true, row => row.data_created_at)
  }
  return scenarios;
}

const filterScenarios = (scenarios, { viewTab, user, searchQuery, findUser }) => {
  let result = scenarios
  if (viewTab === TABS.your_scenarios) {
    result = result?.filter(row => row.user_email === user.email)
  }
  if (isPresent(searchQuery)) {
    const normQuery = searchQuery.toLowerCase();
    result = result?.filter(row => {
      const scenarioUser = findUser(row) || { email: '', full_name: '' };
      return [
        row.display_name,
        scenarioUser.email, scenarioUser.full_name,
        ...(row.actualized || []).flatMap(h => [
          h.display_name || '', formatedDate(h)
        ])
      ].some(value => value.toLowerCase().includes(normQuery))
    })
  }
  return result;
}


const ForecastSimulatorTab = ({
                                home, current_user, current_org, contactsData,
                                loadContacts, loadForecastSimulatorData, createForecastSimulatorRecord,
                                showAlert
                              }) => {
  if (hideForecastSimulatorTab(current_org, current_user)) return null;

  const history = useHistory()
  const allContactsData = useMemo(() => allContacts(contactsData, current_user), [contactsData.contacts, current_user]);
  const findUser = useCallback((scenario) => {
    const userEmail = scenario.user_email;
    if (isBlank(userEmail)) return null;

    return (allContactsData.find(i => i.email === userEmail) || {
      value: userEmail,
      email: userEmail
    })
  }, [contactsData])

  const {
    searchQuery, resetSearch, ...searchOpts
  } = homeSearchState(home)
  const [sortOrder, setSortOrder] = useState(SortByTypes.MOST_RECENT);
  const [viewTab, setViewTab] = useState(TABS.all_scenarios);
  const [shownNewModal, setShownModal] = useState(false)
  const { scenarios, configs } = home.forecast_simulator.data;
  const [viewScenarios, setViewScenarios] = useState(
    sortScenarios(
      filterScenarios(scenarios, { viewTab, user: current_user, searchQuery: searchQuery.trim(), findUser }),
      sortOrder
    )
  );
  checkLoadingEffect(contactsData, loadContacts);
  useEffect(() => {
    if (needToLoadHomepageSection(home, 'forecast_simulator')) loadForecastSimulatorData({ tiny: true })
  }, [home.forecast_simulator.loaded])
  useEffect(() => {
    setViewScenarios(
      sortScenarios(
        filterScenarios(scenarios, { viewTab, user: current_user, searchQuery: searchQuery.trim(), findUser }),
        sortOrder
      )
    )
  }, [home.forecast_simulator.data.scenarios, viewTab, sortOrder, searchQuery])
  useEffect(() => {
    if (isBlank(home.forecast_simulator.error) || !home.forecast_simulator.loaded) return;

    setTimeout(() => {
      showAlert({
        text: home.forecast_simulator.error,
        dismissible: true,
        type: 'danger',
        addClass: 'center-text',
        duration: DURATION
      })
    }, 100)
  }, [home.forecast_simulator])

  const [showLoader, setLoader] = useState(false)
  const isLoading = isHomepageSectionLoading(home, 'forecast_simulator');
  const showNullResults = isBlank(viewScenarios) && (isPresent(searchQuery.trim()) || viewTab !== TABS.all_scenarios)
  const resetAllFilters = () => {
    resetSearch();
    setViewTab(TABS.all_scenarios);
  }
  const submitScenario = useCallback((config_id, display_name, callback) => {
    createForecastSimulatorRecord(config_id, { scenario: { display_name }, tiny: true }, (scenario, errors) => {
      callback(scenario, errors)
      if (scenario) {
        setTimeout(() => {
          history.push(`/forecast_simulator/scenarios/${scenario.id}`)
        }, 100)
      }
    })
  }, []);

  return <>
    <div className="mx-auto dsight-container">
      <div className="row">
        <div className="col-6">
          <h1>Scenarios</h1>
        </div>
        <div className="col-6">
          <Button onClick={() => setShownModal(true)} className="h5 float-end h-36 py-1" hidden={isBlank(configs)}>
            <i className="fas fa-plus fa-lg me-1" />
            <span>New scenario</span>
          </Button>
        </div>
      </div>
      <div className="row">
        <div className={"col search-scenario-block"}>
          <Switcher {...{ viewTab, setViewTab }} />
          <Search {...{searchQuery, resetSearch, ...searchOpts}} />
        </div>
      </div>
      <div className="row">
        <div className="col d-flex">
          <ScenariosCount scenarios_count={(viewScenarios || []).length} />
          <SortByCriteria store={{ sort_order: sortOrder }} onChangeSortingCriteria={setSortOrder} />
        </div>
      </div>
      <div className="row">
        {
          showLoader || isLoading ? <Loader/> :
            showNullResults ? <NullSearchResults {...{ home, resetAllFilters }} /> :
              viewScenarios.map((scenario) =>
                <ScenarioItem key={`scenario-homepage-${scenario.id}`}
                              scenario={scenario} user={findUser(scenario)}/>
              )
        }
      </div>
    </div>
    <div className="modals">
      <NewScenarioModal {...{setLoader, shown: shownNewModal, onClose: () => setShownModal(false), onSubmit: submitScenario }} />
    </div>
  </>
}

const mapStateToProps = ({ home, current_user, current_org, contacts }) => ({
  home, current_user, current_org, contactsData: contacts
});
export default connect(mapStateToProps, {
  loadContacts, loadForecastSimulatorData, createForecastSimulatorRecord, showAlert
})(ForecastSimulatorTab);
