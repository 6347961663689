import React, {useEffect} from 'react';
import Select, { components } from 'react-select';
import {BorderedFilterStyle, MutedDefaultValue} from "../../../tree_wizard/styles/choice_entry_styles";
import { isBlank, isPresent } from "../../../helpers/common";
import { connect } from "react-redux";
import { driversToArray } from "../../../helpers/drivers_helpers";
import CloseIcon from "../../../common/CloseIcon";
import {WIZARD_STEPS, wizardStepDataBy} from "../../../helpers/wizard_helpers";

const removeDriverReference = (slug, setDriverReferences) => {
  setDriverReferences(prev => prev.filter(s => s !== slug));
};

const DriverRow = ({ driverData, slug, setDriverReferences }) =>
  <div className='d-flex mb-2' id={`rowDriverReference-${slug}`} key={`driver-reference-${slug}`}>
    <div className="w-100 p-2 bg-light rounded">
      {driverData.driver.question}
    </div>
    <CloseIcon onClose={() => removeDriverReference(slug, setDriverReferences)} classParams="ms-2 align-self-center" />
  </div>

const DriverReferencesList = ({referencedDrivers, driversData, setDriverReferences}) => (
  <>
    {referencedDrivers.map(slug => {
      const driverData = driversData.find(d => d.driver.slug === slug);
      if (isBlank(driverData)) return null;
      return <DriverRow key={`driver-reference-${slug}`} driverData={driverData} slug={slug} setDriverReferences={setDriverReferences} />;
    })}
  </>
);

const getDriverOptions = (driversData, driver, driverReferences) => {
  return driversData
    .filter(d => !driverReferences?.includes(d.driver.slug) && d.driver.slug !== driver.slug)
    .map(driverData => ({ value: driverData.driver.slug, label: driverData.driver.question }));
};

const SelectAllSubdrivers = ({ childrenSlugs, setDriverReferences }) => {
  if (isBlank(childrenSlugs)) return null;

  const handleSelectAll = () => setDriverReferences(childrenSlugs)

  return <a className="pointer" onClick={handleSelectAll}>
    Select subdrivers
  </a>
}

const DriverReferencesSection = ({
                                   driver, driverReferences, setDriverReferences, isDSight,
                                   driversData, driverData, show, assignDriverEntry = false
                                 }) => {
  if (isDSight || isBlank(driver) || assignDriverEntry) return null;

  const referencedDrivers = driver.referenced_drivers || [];
  const childrenSlugs = driverData.children.filter(c => isPresent(c.driver.question)).map(child => child.driver.slug);
  const onChangeReferences = (select) => {
    setDriverReferences(prev => [...prev, select.value]);
  };

  const CustomOption = (props) => (
    <components.Option {...props}>
      <div className="text-truncate">{props.data.label}</div>
    </components.Option>
  );

  useEffect(() => {
    setDriverReferences(referencedDrivers);
  }, [show, referencedDrivers.length]);

  return (
    <div className="mt-3 mb-0">
      <div className='d-flex'>
        <h3 className='me-auto'>Responses needed for reference</h3>
        <SelectAllSubdrivers {...{childrenSlugs, setDriverReferences}} />
      </div>
      <DriverReferencesList
        referencedDrivers={driverReferences}
        driversData={driversData}
        setDriverReferences={setDriverReferences}
      />
      <Select
        className="mb-3"
        value={{ value: '', label: 'Make a selection' }}
        components={{ IndicatorSeparator: () => null, Option: CustomOption }}
        styles={{...BorderedFilterStyle, ...MutedDefaultValue}}
        onChange={onChangeReferences}
        options={getDriverOptions(driversData, driver, driverReferences)}
      />
    </div>
  );
};

const mapStateToProps = ({ tree, decision, wizard }, { driver, isWizard }) => {
  const stepData = wizardStepDataBy(wizard, WIZARD_STEPS.tree_builder.key) || {};
  const drivers = isWizard ? stepData.drivers : tree.drivers;
  return {
    decision,
    driversData: isPresent(drivers) ? driversToArray(drivers).filter(d => d.driver.question) : [],
    childrenDrivers: isPresent(tree.drivers) ? tree.drivers.find(d => d.driver.slug === driver.slug)?.children || [] : []
  };
};

export default connect(mapStateToProps, {})(DriverReferencesSection);