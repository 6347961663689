import React, {useState, useEffect, useCallback, useMemo} from "react";
import { useHistory } from "react-router-dom";
import DropdownWithSearch from "../../common/DropdownWithSearch"
import { SmallLoader } from "../../common/Loader";
import { isBlank, isPresent } from "../../helpers/common";
import { filterDropdownScenarios, filteredOptions, prepareAllOptionsArray } from "../helpers/helpers";

const ScenarioName = ({ forecast_simulator_scenario, scenarioIsLoaded }) => {
  const history = useHistory();

  const allOptions = useMemo(() =>
    [...filterDropdownScenarios(forecast_simulator_scenario)]
    , [forecast_simulator_scenario.config_scenarios])
  const allOptionsArray = useMemo(() => prepareAllOptionsArray(allOptions), [allOptions])

  const [selectedOption, setSelectedOption] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredScenarios, setFilteredScenarios] = useState(allOptions)
  useEffect(() => {
      setSelectedOption(
        allOptionsArray.find(h => h.id === forecast_simulator_scenario.scenario_id)?.display_name
      )
  }, [forecast_simulator_scenario.scenario]);
  useEffect(() => {
    setFilteredScenarios(
      filteredOptions(allOptions, searchQuery.trim().toLowerCase())
    )
  }, [searchQuery, forecast_simulator_scenario.config_scenarios]);
  const onSelectScenario = useCallback(scenario_id => {
    if (scenario_id === forecast_simulator_scenario.scenario_id) return false;

    const getParams = new URLSearchParams(document.location.search)
    history.push(`/forecast_simulator/scenarios/${scenario_id}?${getParams.toString()}`)
    return false;
  }, [document.location.search, forecast_simulator_scenario.scenario_id])

  const selectedOptionValue = <div className="text-ellipsis me-1">{selectedOption}  </div>

  return <div className="scenario-container text-start mx-3">
    <div className="fs-6 lh-1 mb-1">Simulated scenario</div>
    {
      isPresent(forecast_simulator_scenario.scenario_error) ?
        <span>{forecast_simulator_scenario.scenario_error}</span> :
        isPresent(forecast_simulator_scenario.scenario_hint) ?
          <span>{forecast_simulator_scenario.scenario_hint}</span> :
          scenarioIsLoaded ?
            <div className="fs-5 lh-lg" style={{marginBottom: '-6px'}} hidden={!scenarioIsLoaded}>
              <DropdownWithSearch {...{
                subKey: 'simulated',
                selectedOption: selectedOptionValue,
                searchQuery, setSearchQuery,
                onClick: onSelectScenario,
                active: forecast_simulator_scenario.scenario_id,
                options: filteredScenarios
              }} />
            </div> :
            <SmallLoader />
    }
  </div>
}
export default ScenarioName;
