import React, {Fragment, useEffect, useState} from 'react';
import { connect } from "react-redux";
import DotsLoader from "../../helpers/DotsLoader";
import {isBlank, isPresent} from "../../../../helpers/common";
import {AiUserInstruction, AssistantContentSwitcher, DriverList} from "./AiInputs";
import {
  AI_ERROR_MESSAGE,
  DATA_SOURCES_READY_TEXT, getDotsLoaderText,
  getInitContext, PREPARING_SOURCES_TEXT,
  REVIEWING_DRIVERS_TEXT
} from "../../../../helpers/ai_helpers";
import {MIN_DRIVERS_FOR_AI_ANALYSIS} from "../../../../helpers/drivers_helpers";
import {RichTextSection} from "../../../../common/RichTextEditor";
import {ShowMoreLessRow} from "../../../../common/MoreLessText";

const FilesAnalysis = ({expandedFilesAnalysis, setExpandedFilesAnalysis, file_analysis}) => {
  if (isBlank(file_analysis)) return null;

  return <>
    {
      expandedFilesAnalysis && <div className='mt-3'>
        <RichTextSection {...{text: file_analysis || ''}} />
      </div>
    }
    <ShowMoreLessRow {...{expanded: expandedFilesAnalysis, setExpanded: setExpandedFilesAnalysis}} />
  </>
}

const AiResponseSection = ({sidebar, loaded, setLoaded, error, context, setContext, assistantContent, index, setIndex,
                             apiAiResponseCallback, decision, driverData, type, loadedVs, setLoadedVs, expandedList,
                             setExpandedList, childrenDrivers, instruction, short_summary, response, file_analysis,
                             aiUseOnlyDrivers, countAnsweredChildren, expandedFilesAnalysis, setExpandedFilesAnalysis
                           }) => {
  const {recommendationInputSidebar, decisionInputSidebar, driverInputSidebar} = sidebar
  const [reviewingText, setReviewingText] = useState(DATA_SOURCES_READY_TEXT);

  useEffect(() => {
    if (driverInputSidebar || recommendationInputSidebar || decisionInputSidebar) {
      if (isPresent(context)) {
        setLoaded(true);
        return setLoadedVs(true);
      }

      const initialContext = getInitContext(type, decision, driverData);
      if (isBlank(initialContext)) {
        apiAiResponseCallback()
      } else {
        setContext(initialContext);
        setLoadedVs(true)
        setLoaded(true)
      }
    }
  }, [])

  useEffect(() => {
    assistantContent.length > 0 && setIndex(assistantContent.length - 1)
  }, [assistantContent.length])

  useEffect(() => {
    if(!loadedVs) return;
    setReviewingText(DATA_SOURCES_READY_TEXT);

    setTimeout(() => {
      setReviewingText(REVIEWING_DRIVERS_TEXT);
    }, 10000);
  }, [loadedVs, loaded]);

  if (isPresent(error) || isPresent(decision.error)) return <p className='text-muted mx-3 my-2'>Error: {AI_ERROR_MESSAGE}</p>
  if (!loadedVs) return <DotsLoader text={PREPARING_SOURCES_TEXT} addClass={'mx-3 my-2'} />
  if (!loaded) return <DotsLoader addClass={'mx-3 my-2'} text={getDotsLoaderText(aiUseOnlyDrivers, countAnsweredChildren, reviewingText, assistantContent.length)}/>

  return <Fragment>
    <div className='mx-3'>
      <RichTextSection {...{text: short_summary || ''}} />
      <RichTextSection {...{text: response || ''}} />
      <FilesAnalysis {...{expandedFilesAnalysis, setExpandedFilesAnalysis, file_analysis}} />
    </div>
    {countAnsweredChildren >= MIN_DRIVERS_FOR_AI_ANALYSIS && <DriverList {...{expandedList, setExpandedList, childrenDrivers}} />}
    <AssistantContentSwitcher {...{assistantContent, index, setIndex}} />
    <AiUserInstruction instruction={instruction} index={index} />
  </Fragment>
}

const mapStateToProps = ({ sidebar, decision }) => ({ sidebar, decision })

export default connect(mapStateToProps, {})(AiResponseSection);
