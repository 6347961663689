import React, { useState } from "react";
import { connect } from "react-redux";
import {isBlank, isPresent, successActions} from "../../helpers/common";
import DriverDetailsModal, { modalDriverData } from "../../tree_editor/modals/DriverDetailsModal";
import { updateDriver } from "../../store/modals/actions";
import {DEFAULT_RATING_SCALE, DriverChoiceEntryType, NUMBER_FORMAT} from "../../models/driver";
import * as moment from "moment";
import {buildDriverChoices} from "../../tree_view/modals/entry_modal/helpers";
import {getAssigneeName} from "./DriverAssignModal";
import {collectCollaborationInvites} from "../../helpers/decision_helpers";
import {checkLoadingEffect} from "../../helpers/callbacks_helpers";
import {
  collaboratorCanEditCollaborators,
  isDecider,
  isExistingCollaborator,
  userAlreadyAddedAsAssignee
} from "../../helpers/user_helpers";
import {loadContacts} from "../../store/contacts/actions";
import {driversToArray} from "../../helpers/drivers_helpers";
import {assignDriverWarning} from "../../helpers/alert_helpers";
import {isDSightDecision} from "../../helpers/home_decision_helpers";

export const onDriversInviteCallback = ({
                                          userName,
                                          assignedToUser,
                                          isExistedCollaborator,
                                          isExistedAssignee,
                                          updateAction,
                                          onCloseModal = () => {},
                                          isTemplate = false,
                                          userCanEditCollaborators = false
}) => {
  let isDriversInvite = null;
  if (isExistedCollaborator) isDriversInvite = false;
  if (isExistedAssignee && !isExistedCollaborator) isDriversInvite = true;
  if (!userCanEditCollaborators) isDriversInvite = true;

  if (!isExistedAssignee && !isExistedCollaborator && isPresent(assignedToUser) && !isTemplate && userCanEditCollaborators) {
    setTimeout(() => {
      assignDriverWarning(confirmed => {
        updateAction(!confirmed)
        onCloseModal(true)
      }, userName);
    }, 50);
  } else {
    updateAction(isDriversInvite)
    onCloseModal(true)
  }
};

const ZTreeDriverDetailsModal = ({ modal,
                                   customKey,
                                   title,
                                   show,
                                   decision,
                                   isTemplate = false,
                                   collaborators = [],
                                   driversData,
                                   onClose, contactsData,
                                   allowNew = true,
                                   current_user,
                                   updateDriver }) => {
  if(isBlank(modal.drivers)) return null;

  const driverData = modalDriverData(modal)
  const [inputValue, setInputValue] = useState(driverData?.driver?.question || '')
  const [notes, setNotes] = useState(driverData?.driver?.notes || '')
  const [driverTypeSlug, setDriverTypeSlug] = useState(driverData?.driver?.driver_type_slug || '');
  const [assignedToUser, setAssignedToUser] = useState(driverData?.driver?.assign_to_user || '');
  const [submitState, setSubmitState] = useState(false)
  const [ratingScale, setRatingScale] = useState(driverData?.driver?.rating_scale || DEFAULT_RATING_SCALE.value)
  const [ratingLabels, setRatingLabels] = useState(driverData?.driver?.rating_labels || {})
  const initDate = isPresent(driverData?.driver?.due_date) ? moment(driverData?.driver?.due_date).format('DD MMM, yyyy') : null;
  const [dueDate, setDueDate] = useState(initDate);
  const [driverResponseType, setDriverResponseType] = useState(driverData?.driver?.choice_entry_widget_type || DriverChoiceEntryType.OPEN);
  const [choices, setChoices] = useState(buildDriverChoices(driverData?.driver || {}));
  const [numberFormat, setNumberFormat] = useState(driverData?.driver?.number_format || NUMBER_FORMAT.DEFAULT);
  const [driverReferences, setDriverReferences] = useState(driverData?.driver?.referenced_drivers || []);

  const invites = isPresent(decision.invites) ? collectCollaborationInvites(decision) : []

  checkLoadingEffect(contactsData, () => loadContacts(() => {}, { invite_yourself: true }));

  const userName = getAssigneeName(assignedToUser, collaborators);
  const isExistedCollaborator = isExistingCollaborator(assignedToUser, invites, decision.user);
  const isExistedAssignee = userAlreadyAddedAsAssignee(assignedToUser, driversData);
  const userCanEditCollaborators = isDecider(current_user) || collaboratorCanEditCollaborators(current_user, decision)

  if (isBlank(driverData?.driver)) return null;

  const onSubmitModal = (reloadContactsCallback = () => {}) => {
    setSubmitState(true)
    const updateAction = (isDriversInvite) => updateDriver({
      slug: driverData.driver.slug,
      question: inputValue, notes,
      driver_type: driverTypeSlug,
      assign_to_user: assignedToUser,
      rating_scale: ratingScale,
      rating_labels: ratingLabels,
      number_format: numberFormat,
      due_date: dueDate,
      choice_entry_widget_type: driverResponseType,
      ...(isTemplate ? {} : { drivers_invite: isDriversInvite }),
      driver_references: driverReferences,
      choices
    }, () => {
      onClose();
      setSubmitState(false);
      reloadContactsCallback()
    })

    if (isDSightDecision(decision)) {
      return updateAction(false);
    }

    onDriversInviteCallback({
      userName,
      assignedToUser,
      isExistedCollaborator,
      isExistedAssignee,
      updateAction,
      isTemplate,
      userCanEditCollaborators,
    });
  }

  return <DriverDetailsModal title={title}
                             key={customKey}
                             collaborators={collaborators}
                             isTemplate={isTemplate}
                             driverData={driverData}
                             question={inputValue}
                             setQuestionValue={setInputValue}
                             notes={notes}
                             driverTypeSlug={driverTypeSlug}
                             setDriverTypeSlug={setDriverTypeSlug}
                             setNotes={setNotes}
                             show={show}
                             onSubmitModal={onSubmitModal}
                             assignedToUser={assignedToUser}
                             setAssignedToUser={setAssignedToUser}
                             onClose={onClose}
                             submitState={submitState}
                             ratingScale={ratingScale}
                             setRatingScale={setRatingScale}
                             ratingLabels={ratingLabels}
                             setRatingLabels={setRatingLabels}
                             dueDate={dueDate}
                             setDueDate={setDueDate}
                             driverResponseType={driverResponseType}
                             setDriverResponseType={setDriverResponseType}
                             choices={choices}
                             setChoices={setChoices}
                             numberFormat={numberFormat}
                             setNumberFormat={setNumberFormat}
                             driverReferences={driverReferences}
                             setDriverReferences={setDriverReferences}
                             allowNew={allowNew}
  />
}
const mapStateToProps = ({ modal, contacts, decision, tree, current_user }) => ({
  modal, decision, current_user,
  contactsData: contacts,
  driversData: isPresent(tree.drivers) ? driversToArray(tree.drivers).filter(d => d.driver.question) : []
});
export default connect(mapStateToProps, { updateDriver, loadContacts } )(ZTreeDriverDetailsModal);
