import React from 'react';
import { Highlighter, Token } from 'react-bootstrap-typeahead';
import BaseTypehead from "./BaseTypehead";
import { isBlank } from "../helpers/common";

const renderNewItemTag = (option, { onRemove, tabIndex, disabled }, index) =>
  <Token key={index} onRemove={onRemove} tabIndex={tabIndex} disabled={disabled} option={option}>
    {option.description}
  </Token>

const renderNewItem = (option, { text }, _index) =>
  <Highlighter search={text}>{option.description}</Highlighter>

export default ({ values = [], setValues = (_) => {}, category, selected_categories = [], ...opts }) =>
  <BaseTypehead
    {...opts}
    labelKey="description" filterBy={['description']}
    selected={values}
    options={category.options.filter(opt => selected_categories.some(id => id === opt.id))}
    onChange={(options) => setValues(category, options)}
    renderMenuItemChildren={renderNewItem}
    renderToken={renderNewItemTag}
  />
