import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { equalsArraysIgnoreOrder, isBlank } from '../../../helpers/common';
import { connect } from "react-redux";
import { Accordion } from 'react-bootstrap'
import { calcSelectedCategories } from "./SearchDecisionFilters/CategoriesFilterMenu";
import DSightOnlyFilterCard from "./DSightOnlyFilterCard";
import CategoryCard from "./CategoryCard";
import ResetFiltersCard from "./ResetFiltersCard";
import TemplateUsersCard from "./TemplateUsersCard";
import { filterCategories } from "../../../helpers/categories_helpers";

export const Menu = ({
                       home, org_categories, current_org,
                       loadAction, saveMenu, onSave,
                       isSetPage = false,
                       templateUsersFilter = false, allGroups = false
                     }) => {
  const [submit, setSubmit] = useState(false);
  const calcCategories = calcSelectedCategories(org_categories, home);
  const [dSightOnly, setDSightOnly] = useState(home.d_sight_only);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState(calcCategories);
  const [selectedTemplateUsers, setSelectedTemplateUsers] = useState(home.template_users);
  const [openedSection, setOpenedSection]= useState('');

  useEffect(() => {
    if(saveMenu) {
      save();
      onSave();
    }
  }, [saveMenu]);

  const defaultFilters = useCallback(() => {
    return isBlank(selectedCategoryOptions) && !dSightOnly && isBlank(selectedTemplateUsers)
  },[dSightOnly, selectedCategoryOptions, selectedTemplateUsers]);

  const filtersChanged = useCallback(() => {
    const categoriesChanged = !equalsArraysIgnoreOrder(home.category_options, buildCategoryOptionsFilterValues);
    const templateUsersChanges = !equalsArraysIgnoreOrder(home.template_users, selectedTemplateUsers);
    return home.category_options.length !== buildCategoryOptionsFilterValues.length ||
      dSightOnly !== home.d_sight_only || categoriesChanged || templateUsersChanges
  }, [dSightOnly, selectedCategoryOptions, selectedTemplateUsers.length]);

  const buildCategoryOptionsFilterValues = useMemo(() =>
    selectedCategoryOptions.map(optionData => optionData.options.map((o) => o.id)).flat()
    , [selectedCategoryOptions]);

  const reset = useCallback(() => {
    if(defaultFilters()) return;

    setDSightOnly(false)
    setSelectedCategoryOptions([]);
    setSelectedTemplateUsers([])
  }, [selectedCategoryOptions, selectedTemplateUsers, dSightOnly]);

  const save = useCallback(() => {
    if(filtersChanged()) {
      setSubmit(true);
      loadAction({
        category_options: buildCategoryOptionsFilterValues,
        template_users: selectedTemplateUsers,
        d_sight_only: dSightOnly
      })
      setSubmit(false);
    }
    onSave();
  }, [selectedCategoryOptions, dSightOnly, selectedTemplateUsers]);

  const selected_categories = org_categories.selected_template_categories
  const available_categories = filterCategories(org_categories, selected_categories)

  return <>
    <ResetFiltersCard {...{ save, reset, defaultFilters }} />
    <Accordion alwaysOpen>
      {
        templateUsersFilter &&
          <TemplateUsersCard {...{
            selectedTemplateUsers, setSelectedTemplateUsers, submit, openedSection, setOpenedSection, allGroups
          }} />
      }
      {
        available_categories.map((category, i) =>
          <CategoryCard key={`category-card-${category.slug}`} eventKey={i+2}
                        {...{
                          category, submit, selected_categories,
                          selectedCategoryOptions, setSelectedCategoryOptions,
                          openedSection, setOpenedSection,
                        }} />
        )
      }
      {current_org.dsight && !isSetPage ? <DSightOnlyFilterCard {...{ dSightOnly, setDSightOnly }} /> : null}
    </Accordion>
  </>
}

const mapStateToProps = ({ home, org_categories, current_org }) => ({
  home, org_categories, current_org
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(Menu);
