import { ForecastScenarioRow, groupRowsByCmus, TableRow } from "./ForecastScenarioRow";
import { getKeyByValue, isBlank, isPresent, qSortArray } from "../../helpers/common";
import * as moment from "moment";
import {
  aggregatedByCmu,
  aggregatedByTime,
  combinedAggregation, impactsAggregation
} from "./aggregastion";
import {
  SIMULATED_FACT_HEADER,
  TIME_SCALES,
  ROW_DRIVER_ID_KEY,
  FACTS_HEADER,
  SUB_FACT_HEADER,
  VALUE_SALES_FACT, YTD_HEADER, YTG_HEADER, CELL_TYPES
} from "../../forecast_simulator_scenario/helpers/ag_grid_vars";
import { DEFAULT_SCOPES } from "../../forecast_simulator_scenario/helpers/scopes_helpers";
import { genYearAgoPeriod, TIME_SCALES_KEYS } from "./ForecastTImeScale";
import { isLockedState } from "./state_hyelpers";
import { calculateCAGR, calculateYTD_YTG, getScenarioFiltersJson } from "../../forecast_simulator_scenario/helpers/common";

export const DISPLAY_NAME_LIMIT = 50;
export const isFuturePeriod = (forecastScenario, periodId) => {
  return forecastScenario.allForecastedEditablePeriods.some(period => period.id === periodId);
};

const accumulateKeysByIndex = (rows) => {
  const result = {};
  const prefixMap = {};
  let currentIndex = 0;

  for (const row of rows) {
    const prefix = row.cmus[0];
    if (!(prefix in prefixMap)) {
      prefixMap[prefix] = currentIndex++;
    }

    const index = prefixMap[prefix];
    if (!result[index]) {
      result[index] = [];
    }
    if (!result[index].includes(row.cmusGroupKey)) {
      result[index].push(row.cmusGroupKey);
    }  }

  return result;
};
export class ForecastScenario {
  constructor(scenario, config, timeScale = null, agGridPreparedData = []) {
    this.id = scenario.data.id;
    this.local_id = scenario.id;
    this.default_scenario = scenario.default_scenario;
    this.attributes = scenario.data.attributes;
    this.scenario_rows = isPresent(scenario.rows) ? scenario.rows : scenario.included;
    this.config = config;
    this.timeScale = timeScale ||
      (isPresent(scenario.view_options?.timeScale) ? scenario.view_options.timeScale : TIME_SCALES[0]);
    this.tableCells = scenario.table_cells || [];
    this.openedGroups = scenario.opened_groups || [];
    this.viewOptions = scenario.view_options;
    this.displayName = scenario.display_name;
    this.cmus = scenario.cmus;
    this.aggregatedFact = null;
    this.agGridPreparedData = agGridPreparedData;
  }

  get allEditedCells() {
    return this.tableCells.filter(cell => cell.cell_type === CELL_TYPES.edited);
  }

  get isActive() {
    return !this.isLocked;
  }

  get isLocked() {
    return isLockedState(this.attributes.calculating_state) || isPresent(this.default_scenario)
  }

  get isAnnualTimeScale() {
    return this.timeScale === TIME_SCALES_KEYS.year;
  }

  updateAgGridPreparedRows(data) {
    this.agGridPreparedData = data;
  }

  setTimeScale(timeScale) {
    if (this.timeScale !== timeScale) {
      this._rows = null;
      this._scenariosRows = null;
      this._forecastedCAGRPeriods = null;
      this._historicalCAGRPeriods = null;
      this._YTDPeriods = null;
      this._YTDPreYearPeriods = null;
      this._YTGPeriods = null;
      this._YTGPrevYearPeriods = null;
      this.timeScale = timeScale;
    }
  }

  get actualEditedCells() {
    return this.tableCells.filter(cell => cell.cell_type === CELL_TYPES.edited);
  }

  get actualRunModelCells() {
    return this.tableCells.filter(cell => cell.cell_type === CELL_TYPES.run_model);
  }

  addedComparisonRows(rows) {
    const rowDriverIds = rows.map(row => row[ROW_DRIVER_ID_KEY]);
    return this.openedGroups?.flatMap((group) => group.added_rows).filter(row => rowDriverIds.some(id => row[ROW_DRIVER_ID_KEY].includes(id)));
  }

  get openedGroupsIds() {
    return this.openedGroups?.map(group => group.id);
  }

  get simulationScopesData() {
    const filterScopes = this.viewOptions.scopes || {};
    const cmusGroups = Object.keys(filterScopes).map(columnId => {
      const { cmus, scope } = filterScopes[columnId];
      switch(scope) {
        case DEFAULT_SCOPES.custom:
          return cmus.map(cmuId => parseInt(cmuId));
        case DEFAULT_SCOPES.visible:
          const tableFilters = getScenarioFiltersJson(this)
          if (isBlank(tableFilters)) return [];

          const columnData = this.config.cmuColumns.find(column => column.id === parseInt(columnId))
          return tableFilters[columnData.displayName]?.values?.map(cmuValue => parseInt(getKeyByValue(columnData.values, cmuValue))) || [];
        default:
          return []
      }
    }).filter(isPresent)
    return {
      filterScopes, cmusGroups
    }
  }

// Helper function to aggregate row data
  aggregateRowData(rows, config) {
    const aggregatedRowData = {};
    config?.cmuHeaders.forEach(column => {
      aggregatedRowData[column.displayName] = rows[0].fetchCmu(column);
    });
    const driver = rows[0].selectedDriver;
    aggregatedRowData[FACTS_HEADER] = this.driverWithMeasure(driver);
    aggregatedRowData[SUB_FACT_HEADER] = SIMULATED_FACT_HEADER;
    aggregatedRowData[ROW_DRIVER_ID_KEY] = rows[0].cmusDriverId;
    return { aggregatedRowData, driver };
  }

// Helper function to process periods
  processPeriods(rows, driver, visiblePeriods, cachedRow, aggregatedRowData, config) {
    if (isPresent(driver.driverRules)) {
      this.allTimeScalePeriods().forEach(period => {
        if (visiblePeriods.some(p => p.id === period.id)) {
          if (isBlank(cachedRow[period.name]) || cachedRow[period.name] === undefined) {
            const rowsInPeriod = rows.filter(row => row.isInTimePeriod(period));
            const { value, format } = aggregatedByTime(rowsInPeriod, driver, period, config);
            if (format) {
              aggregatedRowData[period.name] = value;
            }
          } else {
            aggregatedRowData[period.name] = cachedRow[period.name];
          }
        } else if (isBlank(cachedRow[period.name])) {
          aggregatedRowData[period.name] = undefined;
        }
      });
    }
  }

  processCAGR(aggregatedRowData) {
    if (this.showCAGR) {
      aggregatedRowData[this.CAGRHistoricalHeader] = calculateCAGR({
        rowNode: {
          leafGroup: true,
          allLeafChildren: [{ data: aggregatedRowData }]
        }
      }, this, this.historicalCAGRPeriods);
      aggregatedRowData[this.CAGRForecastedHeader] = calculateCAGR({
        rowNode: {
          leafGroup: true,
          allLeafChildren: [{ data: aggregatedRowData }]
        }
      }, this, this.forecastedCAGRPeriods);
    }
  }

  processYTDAndYTG(aggregatedRowData) {
    if (this.showYTG) {
      aggregatedRowData[this.YTDHeader] = calculateYTD_YTG({
        rowNode: {
          leafGroup: true,
          allLeafChildren: [{ data: aggregatedRowData }]
        }
      }, this, this.YTDPeriods, this.YTDPreYearPeriods);
      aggregatedRowData[this.YTGHeader] = calculateYTD_YTG({
        rowNode: {
          leafGroup: true,
          allLeafChildren: [{ data: aggregatedRowData }]
        }
      }, this, this.YTGPeriods, this.YTGPrevYearPeriods);
    }
  }

// Helper function to update cached rows
  updateCachedRows(aggregatedRowData, cachedRow) {
    Object.assign(cachedRow, aggregatedRowData);
    const filteredData = this.agGridPreparedData.filter(row => row[ROW_DRIVER_ID_KEY] !== aggregatedRowData[ROW_DRIVER_ID_KEY]);
    this.updateAgGridPreparedRows([...filteredData, cachedRow]);
    return new TableRow(cachedRow);
  }

// Refactored preparedRowsForTable function
  preparedRowsForTable(cmusGroups = null, visiblePeriods = this.allTimeScalePeriods()) {
    const filteredRows = cmusGroups ? this.findRowsByCmusGroups(cmusGroups) : this.rows;
    return Object.values(groupRowsByCmus(filteredRows)).map(rows => {
      const { aggregatedRowData, driver } = this.aggregateRowData(rows, this.config);
      let cachedRow = this.agGridPreparedData.find(row => row[ROW_DRIVER_ID_KEY] === aggregatedRowData[ROW_DRIVER_ID_KEY]);
      if (!cachedRow) {
        cachedRow = { ...aggregatedRowData };
      }
      this.processPeriods(rows, driver, visiblePeriods, cachedRow, aggregatedRowData, this.config);
      this.processCAGR(aggregatedRowData);
      this.processYTDAndYTG(aggregatedRowData);
      return this.updateCachedRows(aggregatedRowData, cachedRow);
    }).map(aggregatedRow =>
      this.allViewHeaders.reduce((acc, header) => {
        acc[header] = aggregatedRow.fetchData(header);
        return acc;
      }, {})
    );
  }

  get groupFields() {
    if (isBlank(this._groupFields)) {
      this._groupFields = this.config?.cmuHeaders.map(column => column.displayName) || [];
    }
    return this._groupFields;
  }

  get firstForecastedPeriod() {
    if (isBlank(this._firstForecastedPeriod)) {
      this._firstForecastedPeriod = this.config.firstForecastedPeriod ||
        qSortArray(
          this.rows, true, row => row.timePeriod.startDate
        ).find(row => !row.attributes.actualized)?.timePeriod;
    }
    return this._firstForecastedPeriod;
  }

  get allForecastedEditablePeriods() {
    if (this.isLocked) return [];
    if (isBlank(this._allForecastEditablePeriods)) {
      const timeScale = this.editableTimeScaleKey;
      this._allForecastEditablePeriods = this.config.getTimeScaleBy(timeScale).timePeriods
        .filter(period => period.startDate >= this.firstForecastedPeriod.startDate);
    }
    return this._allForecastEditablePeriods;
  }

  get allForecastedAndPrevEditablePeriods() {
    if (isBlank(this._allForecastedAndPrevEditablePeriods)) {
      const prevYearStartDate = moment(this.firstForecastedPeriod.startDate).subtract(1, 'year').startOf('year');
      this._allForecastedAndPrevEditablePeriods = this.config.getTimeScaleBy(this.timeScale).timePeriods
        .filter(period => period.startDate >= prevYearStartDate);
    }
    return this._allForecastedAndPrevEditablePeriods;
  }

  get editableTimeScaleKey() {
    return this.config.dataTimeScale.key;
  }

  get isEditableTimeScale() {
    return this.editableTimeScaleKey === this.timeScale;
  }

  allTimeScalePeriods(timeScale = this.timeScale) {
    return this.config?.getTimeScaleBy(timeScale)?.timePeriods;
  }

  periods({ from = null, to = null } = this.viewOptions, timeScale = this.timeScale) {
    const fromDate = isPresent(from) ? moment(from) : false
    const toDate = isPresent(to) ? moment(to) : false

    return this.allTimeScalePeriods(timeScale).filter(period => {
      if (fromDate && fromDate > period.startDate) return false;
      if (toDate && toDate < period.endDate) return false;

      return true;
    }) || [];
  }

  fetchScopedRows({
                    cmu = null, period = null, periods = [], cmus = [], cmusGroups = [], rows = false
                  }) {
    return (rows || this.scenariosRows).filter(row =>
      (isBlank(period) || row.isInTimePeriod(period)) &&
      (isBlank(periods) || periods.some(p => row.isInTimePeriod(p))) &&
      (isBlank(cmu) || row.cmus.includes(cmu)) &&
      (isBlank(cmus) || cmus.filter(isPresent).every(id => row.cmus.includes(id))) &&
      (isBlank(cmusGroups) || cmusGroups.every(cmusGroup => cmusGroup.some(id => row.cmus.includes(id))))
    );
  }

  aggregateBy({
                cmu = null, cmus = [], cmusGroups = [], rows = false,
                period, driver
              }) {
    const allCmuRows = this.fetchScopedRows({
      cmu, period, cmus, cmusGroups, rows
    });
    if (isBlank(allCmuRows)) return null;
    if (isPresent(driver?.driverRules)) {
      const aggregateFunction = period.timeScale.isInitData ? aggregatedByCmu : combinedAggregation
      const {format, value} = aggregateFunction(allCmuRows, driver, period, this.config)
      if (format) return value;
    }
    return 0.0;
  }

  aggregateAbsImpactBy({
                         cmu = null, cmus = [], cmusGroups = [], rows = false,
                         period, driver, metric
                       }) {
    const allCmuRows = this.fetchScopedRows({
      cmu, period, cmus, cmusGroups, rows
    });
    if (isPresent(driver?.decompRules[metric.id])) {
      const {format, value} = impactsAggregation(allCmuRows, driver, period, this.config, metric)
      if (format) {
        return value;
      }
    }
    return 0.0;
  }

  get periodHeaders() {
    try {
      return this.periods(this.viewOptions);
    } catch (e) {
      console.error("Error while parsing period headers", e);
      return []
    }
  }

  get periodHeadersNames() {
    return this.periodHeaders.map(period => period.name)
  }

  get allViewHeaders() {
    return [
      ROW_DRIVER_ID_KEY,
      ...this.config?.cmuHeaders.map(column => column.displayName),
      FACTS_HEADER,
      SUB_FACT_HEADER,
      ...this.allTimeScalePeriods().map(period => period.name),
      this.CAGRHistoricalHeader,
      this.CAGRForecastedHeader,
      this.YTDHeader,
      this.YTGHeader
    ].filter(isPresent);
  }

  get YTDPeriods() {
    if(this._YTDPeriods) return this._YTDPeriods;

    this._YTDPeriods = this.periods({ from: moment().startOf('year'), to: this.firstForecastedPeriod.startDate });
    return this._YTDPeriods;
  }

  get YTDPreYearPeriods() {
    if(this._YTDPreYearPeriods) return this._YTDPreYearPeriods;

    this._YTDPreYearPeriods = this.periods({ from: moment().subtract(1, 'year').startOf('year'), to: genYearAgoPeriod({ period: this.firstForecastedPeriod }).startDate });
    return this._YTDPreYearPeriods;
  }

  get YTGPeriods() {
    if(this._YTGPeriods) return this._YTGPeriods;

    this._YTGPeriods = this.periods({ from: this.firstForecastedPeriod.startDate, to: moment().endOf('year') });
    return this._YTGPeriods;
  }

  get YTGPrevYearPeriods() {
    if(this._YTGPrevYearPeriods) return this._YTGPrevYearPeriods;

    this._YTGPrevYearPeriods = this.periods({ from: genYearAgoPeriod({ period: this.firstForecastedPeriod }).startDate, to: moment().subtract(1, 'year').endOf('year') });
    return this._YTGPrevYearPeriods;
  }

  get historicalCAGRPeriods () {
    if(this._historicalCAGRPeriods) return this._historicalCAGRPeriods;

    this._historicalCAGRPeriods = [...this.periods({ to: this.lastActualPeriod.startDate }), this.lastActualPeriod];
    return this._historicalCAGRPeriods;
  }

  get forecastedCAGRPeriods () {
    if(this._forecastedCAGRPeriods) return this._forecastedCAGRPeriods;

    this._forecastedCAGRPeriods = [this.lastActualPeriod, ...this.periods({ from: this.lastActualPeriod.endDate })];
    return this._forecastedCAGRPeriods;
  }

  get lastActualPeriod() {
    return this.periods({ to: this.firstForecastedPeriod.startDate }).pop();
  }

  get showCAGR() {
    return this.isAnnualTimeScale;
  }

  get showYTG() {
    return !this.isAnnualTimeScale;
  }

  get CAGRHistoricalHeader() {
    if(!this.showCAGR) return null;

    return `CAGR ${this.historicalCAGRPeriods[0].name}/${this.historicalCAGRPeriods[this.historicalCAGRPeriods.length - 1].name}`;
  }

  get CAGRForecastedHeader() {
    if(!this.showCAGR) return null;

    return `CAGR ${this.forecastedCAGRPeriods[0].name}/${this.forecastedCAGRPeriods[this.forecastedCAGRPeriods.length - 1].name}`;
  }

  get YTGHeader() {
    if(!this.showYTG) return null;

    return YTG_HEADER;
  }

  get YTDHeader() {
    if(!this.showYTG) return null;

    return YTD_HEADER;
  }

  get viewHeaders() {
    return [
      ROW_DRIVER_ID_KEY,
      ...this.config?.cmuHeaders.map(column => column.displayName),
      FACTS_HEADER,
      SUB_FACT_HEADER,
      ...this.periodHeadersNames,
      this.CAGRHistoricalHeader,
      this.CAGRForecastedHeader,
      this.YTDHeader,
      this.YTGHeader
    ].filter(isPresent);
  }

  get rows() {
    if (isBlank(this._rows)) {
      this._rows = qSortArray(
        this.scenario_rows.flatMap(hash =>
          this.config.viewDriversBy(this.timeScale).map(column => new ForecastScenarioRow(hash, this, column))
        ), true, row => row.cmusSortKey
      )
    }
    return this._rows;
  }

  get cmusTable() {
    if (isBlank(this._cmusTable)) {
      this._cmusTable = accumulateKeysByIndex(this.rows);
    }
    return this._cmusTable;
  }

  get scenariosRows() {
    if (isBlank(this._scenariosRows)) {
      this._scenariosRows = qSortArray(
        this.scenario_rows.map(hash => new ForecastScenarioRow(hash, this)),
        true, row => row.cmusSortKey
      )
    }
    return this._scenariosRows;
  }

  driverWithMeasure(driver) {
    if(isBlank(driver)) return '';

    return [driver.displayName, driver.measure].filter(isPresent).join(', ')
  }

  get valueSalesDriverName() {
    if (isBlank(this._valueSalesDriver)) {
      const driver = this.findFactByDriverName(VALUE_SALES_FACT);
      this._valueSalesDriver = this.driverWithMeasure(driver);
    }
    return this._valueSalesDriver;
  }

  updateScenarioRows(rows) {
    this.scenario_rows = rows;
    this.agGridPreparedData = [];
    this._rows = null;
    this._scenariosRows = null;
  }

  findFactByDriverName(driverName) {
    return this.config.allFactsColumns.find(column => column.displayName === driverName);
  }

  findRowsByCmusGroups(cmusGroups) {
    return this.rows.filter(row => cmusGroups.some(cmusGroup => cmusGroup.every(id => row.cmus.includes(id))))
  }

  findRowBy(cmus, periodId, driverId) {
    return this.rows.find(row => {
      return row.selectedDriver.id === Number(driverId) &&
        row.cmus.every(cmu => cmus.includes(cmu)) && row.timePeriod.id === Number(periodId)
    })
  }
}
