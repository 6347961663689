import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooter } from "../../common/modals";
import { isBlank, isPresent } from "../../helpers/common";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { loadOrgCategories } from "../../store/org_categories/actions";
import { loadDashboardTemplateAdditionalData, startNewDecision } from "../../store/homepage/actions";
import { CategoryFormInput } from "../../decision_categories/CategoriesForm";
import { onChangeCategoryCallback } from "../../tree_wizard/steps_wizard/steps/TreeDecisionCategorizationStep";
import { BASE_SECTION } from "../../store/homepage/reducers";
import { Loader } from "../../common/Loader";
import { checkLoadingEffect } from "../../helpers/callbacks_helpers";
import { initAutofocusInput } from "./helpers";
import DecisionTitleRow from "../../tree_wizard/helpers/DecisionTitleRow";
import DecisionFlowRow from "../../tree_wizard/helpers/DecisionFlowRow";
import { fetchRequiredCategories } from "../../helpers/categories_helpers";
import { getSectionSlugFromParam } from "../../helpers/set_helpers";
import {RichMutedText} from "../../common/RichTextEditor";
import { flow } from "../../helpers/decision_flows";
import { getScenarioIdFromParam } from "../../forecast_simulator_scenario/helpers/helpers";

export const showStartDecisionModal = (modal) => modal.type === "StartDecisionModal" && modal.shown;
export const openStartDecisionModal = ({ openModal, template = null, ...opts }) => openModal({ ...opts, type: 'StartDecisionModal', template })

const CategoriesForm = ({ org_categories, selectedCategories, playbook_notes, ...opts }) => {
  const fetchedCategories = fetchRequiredCategories(org_categories, selectedCategories)
  if (isBlank(fetchedCategories))return null;

  return <>
    <h3 className='mt-3'>Categories</h3>
    <div className='mb-2' hidden={isBlank(playbook_notes?.categorization)}>
      <RichMutedText text={playbook_notes?.categorization} />
    </div>
    {
      fetchedCategories.map(category =>
        <CategoryFormInput key={`category-input-${category.slug}-wrapper`} addClass={'mb-2'} {...{ ...opts, category }} />
      )
    }
  </>
}

export const startDecisionModalState = ({
                                          template = {}, report = {},
                                          org_categories, additionalTemplatesData,
                                          isDecisionSet = false, decision_set = null, decisionSetSlug = null,
                                          shown, onClose = () => false,
                                          onSubmit,
                                          loadOrgCategories, loadDashboardTemplateAdditionalData, resetSidebars
                                        }) => {
  const additionalTemplateData = additionalTemplatesData[template?.slug] || BASE_SECTION
  const record_by_default = isPresent(template?.record_by_default) || additionalTemplateData.data?.record_by_default
  const [description, setDescription] = useState(template?.description || '');
  const [flowEnum, setFlowEnum] = useState(isPresent(report) || record_by_default ? flow.TREE_HISTORICAL : flow.TREE_RECOMMENDATION)
  const [selectedCategories, setSelectedCategories] = useState(
    (additionalTemplateData.data?.selected_categories || []).filter(({required}) => required)
  )
  const [categoriesData, setCategoriesData] = useState(additionalTemplateData.data?.category_options || [])
  const [playbook_notes, setPlaybookNotes] = useState(additionalTemplateData.data?.playbook_notes || null)
  const onChangeCategory = onChangeCategoryCallback(categoriesData, setCategoriesData)
  const [submit, setSubmit] = useState(false);

  const history = useHistory();
  const inputRef = initAutofocusInput([shown])

  const resetPlainInputs = () => {
    setDescription(template?.description || '')
    setFlowEnum(isPresent(report) || isPresent(record_by_default) ? flow.TREE_HISTORICAL : flow.TREE_RECOMMENDATION)
    setSubmit(false)
  }
  const resetReportFlowEnum = () => {
    setFlowEnum(flow.TREE_HISTORICAL)
  }
  const resetAdditionalTemplateData = () => {
    setCategoriesData(additionalTemplateData.data?.category_options || [])
    setPlaybookNotes(additionalTemplateData.data?.playbook_notes || null)
    setSelectedCategories((
      additionalTemplateData.data?.selected_categories || []).filter(({required}) => required)
    )
    setFlowEnum(isPresent(report) || isPresent(record_by_default) ? flow.TREE_HISTORICAL : flow.TREE_RECOMMENDATION)
  }

  useEffect(resetReportFlowEnum, [report?.slug])
  useEffect(resetPlainInputs, [template?.slug])
  useEffect(resetAdditionalTemplateData, [template?.slug, additionalTemplateData.loaded])
  useEffect(() => {
    if (isPresent(template) && !additionalTemplateData.loaded) {
      loadDashboardTemplateAdditionalData(template.slug)
    }
  }, [template, additionalTemplateData.loaded])

  checkLoadingEffect(org_categories, loadOrgCategories)

  const onCloseModal = () => {
    onClose();
    resetPlainInputs();
    resetAdditionalTemplateData();
  }

  const isFormFilled = () => {
    if (isBlank(description) || isBlank(flowEnum)) return false;

    return fetchRequiredCategories(org_categories, selectedCategories)
      .every(({ options }) => options.some(({ id }) => categoriesData.includes(id)))
  }

  const onCreate = () => {
    setSubmit(true)
    const forecast_scenario_id = getScenarioIdFromParam(document);
    const data = {
      description, flow_enum: flowEnum, categories: categoriesData,
      template_slug: template?.slug,
      report_slug: report?.slug,
      forecast_scenario_id
    }
    if (isDecisionSet) {
      data.decision_set_slug = decisionSetSlug || decision_set.slug
      data.section_slug = getSectionSlugFromParam(document)
    }
    onSubmit(data, slug => {
      if (resetSidebars) resetSidebars()
      onCloseModal();
      if (isPresent(slug)) history.push(`/decisions/${slug}/tree_wizard`)
    })
  }
  const isLoading = () => org_categories.loading || additionalTemplateData.loading

  return {
    description, setDescription,
    flowEnum, setFlowEnum,
    org_categories, categoriesData, onChangeCategory, selectedCategories,
    playbook_notes,
    submit, ref: inputRef,
    onCloseModal, onCreate, isFormFilled, isLoading
  }
}

const StartDecisionModalForm = React.forwardRef(({
                                                   description, setDescription,
                                                   flowEnum, setFlowEnum,
                                                   org_categories, categoriesData, onChangeCategory,
                                                   selectedCategories,
                                                   playbook_notes, submit
                                                 }, ref) =>
  <>
    <DecisionTitleRow {...{ description, setDescription, submit, playbook_notes }} ref={ref} />
    <DecisionFlowRow {...{ flowEnum, setFlowEnum }} />
    <CategoriesForm {...{
      org_categories, selectedCategories, categoriesData, onChangeCategory,
      submitState: submit, required: true, hideRequiredTitle: false, playbook_notes
    }} className="w-100" />
  </>
)

export const StartDecisionModalBody = React.forwardRef(({ onCloseModal, isLoading, ...opts }, ref) =>
  <Modal.Body>
    <CloseIcon onClose={onCloseModal} />
    <h2>Create a decision</h2>
    {
      isLoading() ? <Loader /> : <StartDecisionModalForm {...opts} ref={ref} />
    }
  </Modal.Body>
)

const StartDecisionModal = ({ shown, ...opts }) => {
  const {
    submit,
    onCloseModal, onCreate, isFormFilled,
    ...stateOpts
  } = startDecisionModalState({ ...opts, shown })

  return <Modal enforceFocus={false} size="md" backdrop="static" show={shown} onHide={onCloseModal}>
    <StartDecisionModalBody {...{ ...stateOpts, onCloseModal, submit }}/>
    <ModalDoneFooter onClose={onCreate} disabled={!isFormFilled() || submit} />
  </Modal>
}
const mapStateToProps = ({ current_user, modal, org_categories, home, decision_set }, props) => ({
  current_user, org_categories, decision_set,
  template: isPresent(props.report) ? {} : modal.template,
  additionalTemplatesData: isPresent(props.report) ? {} : home.create_decision_additional_data
});
export const mapDispatchToProps = (dispatch) => ({
  onSubmit: (data = {}, callback) => dispatch(startNewDecision(data, callback)),
  loadDashboardTemplateAdditionalData: (slug) => dispatch(loadDashboardTemplateAdditionalData(slug)),
  loadOrgCategories: () => dispatch(loadOrgCategories())
})
export default connect(mapStateToProps, mapDispatchToProps)(StartDecisionModal);
