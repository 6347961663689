import React, {Fragment, useEffect, useState} from 'react';
import {connect} from "react-redux";
import AlertsSection from "../alerts";
import {Loader} from "../common/Loader";
import {isStoreLoading} from "../helpers/callbacks_helpers";
import DriverInputRightPanel from "../tree_view/side_panel/DriverInputRightPanel";
import {initTreeChannelSubscription} from "../helpers/channel_helpers";
import {updateTreeChannelData} from "../store/channels/actions";
import {closeDriverInputSidebarBySlug, resetSidebars, setDriverInputSidebarOpen} from "../store/sidebar/actions";
import {closeAndResetDriverDetailsModal, closeModal} from "../store/modals/actions";
import {loadAssignedDriversData, updateAssignedDriversData} from "../store/assigned_drivers/actions";
import AssignedDriversPageHeader from "./header/AssignedDriversPageHeader";
import {updateEntryPointData} from "../EntryPoint";
import {isPresent, successActions} from "../helpers/common";
import AssignedDriversTree from "../tree_view/accordion_tree/AssignedDriversTree";
import {isGuest} from "../helpers/home_helpers";
import {updateTreeData} from "../store/tree/common_actions";
import {useHistory} from "react-router-dom";
import DriverAssignModal, {showDriverAssign} from "../tree_editor/modals/DriverAssignModal";
import {openDriverInputSidebarCallback} from "../tree_view";
import {DriverAiResponsePanel} from "../tree_view/side_panel/AiResponsePanel";

const Sidebars = ({}) => <Fragment>
  <div className="sidebars">
    <DriverInputRightPanel key="driver-input-side-panel" assignDriverEntry={true} />
    <DriverAiResponsePanel key="driver-files-summarize-panel" assignDriverEntry={true} />
  </div>
</Fragment>

const Wrapper = ({children, activeLogo, modal, closeModal, ...opts}) =>
  <Fragment>
    <AlertsSection/>
    <AssignedDriversPageHeader activeLogo={activeLogo} isDriverAssignHeader={true}/>
    <div className="d-flex vertical-tree-container">
      {children}
    </div>
    <Sidebars {...opts} />
    <div className="modals">
      <DriverAssignModal key={`driver-assign-modal-${modal.slug}`} show={showDriverAssign(modal)} onClose={closeModal} />
    </div>
  </Fragment>

export const AssignedDriversView = ({
                                      assigned_drivers, loadAssignedDriversData, updateTreeChannelData,
                                      closeDriverInputSidebarBySlug, closeAndResetDriverDetailsModal, decision,
                                      updateAssignedDriversData, match, tree, updateTreeData, resetSidebars,
                                      modal, closeModal, setDriverInputSidebarOpen
                                   }) => {
  const [activeLogo, setActiveLogo] = useState(true);
  const history = useHistory();

  useEffect(() => {
    updateEntryPointData(match, 'assigned_drivers')
    if (assigned_drivers.loaded && isPresent(match?.params?.id) && (decision.slug !== match?.params?.id || tree.locationPath.includes('tree_viewer') || tree.locationPath.includes('homepage'))) {
      successActions(true, [resetSidebars]);
      updateAssignedDriversData({ loaded: false })
      updateTreeData({ locationPath: history.location.pathname })
    }
  }, [match?.params?.id, assigned_drivers.loaded, decision, tree.locationPath])

  useEffect(() => {
    if (assigned_drivers.loaded || assigned_drivers.loading) return;

    loadAssignedDriversData((success, decision = {}, current_user = {}, active_logo, tree_data = {}) => {
      if (success) {
        isGuest(current_user) && setActiveLogo(active_logo)
        updateTreeData({ locationPath: history.location.pathname })
        initTreeChannelSubscription(decision, current_user, updateTreeChannelData, closeDriverInputSidebarBySlug, closeAndResetDriverDetailsModal)

        const getParams = new URLSearchParams(document.location.search)
        openDriverInputSidebarCallback(tree_data, getParams, decision, current_user, setDriverInputSidebarOpen, history)
      }
    });
  }, [assigned_drivers.loaded]);

  if (isStoreLoading(assigned_drivers)) {
    return <Wrapper {...{ assigned_drivers, activeLogo, modal, closeModal }}>
      <Loader />
    </Wrapper>;
  } else {
    return <Wrapper {...{ assigned_drivers, activeLogo, modal, closeModal }}>
      <AssignedDriversTree />
    </Wrapper>;
  }
}
export const mapStateToProps = ({ assigned_drivers, tree, decision, modal }) => ({
  assigned_drivers, tree, decision, modal
});
export const mapDispatchToProps = (dispatch) => ({
  loadAssignedDriversData: (callback) => { dispatch(loadAssignedDriversData(callback)) },
  updateTreeChannelData: (data) => {dispatch(updateTreeChannelData(data))},
  closeDriverInputSidebarBySlug: (driverSlug) => {dispatch(closeDriverInputSidebarBySlug(driverSlug))},
  closeAndResetDriverDetailsModal: (driverSlug) => dispatch(closeAndResetDriverDetailsModal(driverSlug)),
  updateAssignedDriversData: (data) => {dispatch(updateAssignedDriversData(data))},
  updateTreeData: (data) => {dispatch(updateTreeData(data))},
  resetSidebars: () => {dispatch(resetSidebars())},
  closeModal: () => {dispatch(closeModal())},
  setDriverInputSidebarOpen: (driverSlug) => {dispatch(setDriverInputSidebarOpen(true, driverSlug))},
});
export default connect(mapStateToProps, mapDispatchToProps)(AssignedDriversView);
